import { SubNavMenuItem } from './subNavMenuItem';
import gsap from 'gsap';

export class SubNavigationGroupAnimation {
  constructor(element, options = { animDuration: 0.8 }) {
    this.container = element;
    this.menuItems = Array.from(this.container.querySelectorAll('.sub-nav__link')).map(
      el => new SubNavMenuItem(el, options),
    );
    this.columns = Array.from(this.container.querySelectorAll('.sub-nav__column'));

    if (this.columns.length === 0) {
      this.columns = [this.container];
    }
  }

  show() {
    if (this.menuItems.length === 0) {
      return;
    }

    const stagger = 0.3 / this.menuItems.length;
    this.tl = gsap.timeline({
      stagger: stagger,
    });

    this.tl.to(this.menuItems, {
      delay: 0.3,
      stagger: {
        each: stagger,
        duration: 0.3,
        ease: 'power2.inOut',
        onComplete: function(args) {
          this.targets()[0].animating = false;
        },
        onStart: function(args) {
          this.targets()[0].show();
        },
      },
      onStart: () => {
        this.columns.forEach(col => col.classList.remove('sub-nav__column--initial'));
      },
    });
  }

  hide() {
    if (this.tl) {
      this.tl.pause();
      this.tl.seek(0);
    }
    this.menuItems.forEach(link => link.hide());
    this.columns.forEach(col => col.classList.add('sub-nav__column--initial'));
  }
}