class SubscriptionManagementModule {
  constructor(element) {
    this.container = element;
    this.init();
  }

  init() {
    this.loadFormPromise = new Promise((resolve, reject) => {
      document.addEventListener('marketoFormLoaded', event => {
        resolve(event.detail);
      });
    });
    this.fetchUserData()
      .then(async userData => {
        this.userData = userData;
        return this.loadFormPromise;
      })
      .then(form => {
        return this.initForm(form);
      })
      .catch(ex => {
        window.location.href = '/';
      });
  }

  async initForm(form) {
    if (!this.userData) {
      this.userData = {
        email: this.email,
        id: this.id
      }
    }

    this.populateForm(form, this.userData);

    const checkUnsubscribe = this.container.querySelector('input[name="Unsubscribed"]');

    checkUnsubscribe.addEventListener('change', () => {
      if (checkUnsubscribe.checked) {
        this.container.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
          if (checkbox.name !== 'Unsubscribed') {
            checkbox.checked = false;
          }
        });
      }
    });

    const otherCheckboxes = this.container.querySelectorAll('input[type="checkbox"]');
    otherCheckboxes.forEach(checkbox => {
      checkbox.addEventListener('change', () => {
        if (checkbox.checked && checkbox.name !== 'Unsubscribed') {
          checkUnsubscribe.checked = false;
        } else if (!otherCheckboxes.some(cb => cb.checked)) {
          checkUnsubscribe.checked = true;
        }
      });
    });

    this.container.querySelector('input[name="Email"]').setAttribute('readonly', 'true');
  }

  populateForm(form, userData) {
    const fields = {
      ...userData,
    };

    fields['Email'] = userData.email;
    delete fields.email;

    fields['FirstName'] = userData.firstName;
    delete fields.firstName;

    fields['LastName'] = userData.lastName;
    delete fields.lastName;

    form.setValues(fields);
    form.validate();
  }

  async fetchUserData() {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const id = params.get('id');
    const locale = document.documentElement.lang;

    if (!email || !id) {
      return;
    }

    this.email = email;
    this.id = id;

    const body = JSON.stringify({ id, email, locale });
    const response = await fetch('/.netlify/functions/fetch-subscriptions', {
      method: 'POST',
      body,
    });
    const data = await response.json();
    return data.lead;
  }
}

export default element => new SubscriptionManagementModule(element);
