import { SimplifiedEventListener } from '../../../../../../_scripts/utils/simplifiedEventListener';

export class MobileNavigationItem extends SimplifiedEventListener {
  constructor(element, callbacks = {}) {
    super(element);

    this.key = this.container.dataset.navButton;
    this.callbacks = callbacks;
  }

  init() {
    this.on('click', () => this.callbacks.show(this.key));
  }

  set active(value) {
    this._active = value;
  }

  get active() {
    return this._active;
  }
}