const DebugOn = (e) => {
  if (e.key == "d") {
    const debugLabels = document.querySelectorAll("[data-debug]");
    debugLabels.forEach((label) => {
      if (label.classList.contains("hidden")) {
        label.classList.remove("hidden");
      } else {
        label.classList.add("hidden");
      }
    });
  }
};
export default DebugOn;
