const DriftChatbot = (element, PopupWithModules) => {
  const DRIFT_HORIZONTAL_PADDING = 24;
  const DRIFT_VERTICAL_PADDING = 34;
  let chatOpen = false;
  let resizeTimeout = null;

  const getHorizontalOffset = () => {
    const headerContainer = document.querySelector('header.header[data-header]');
    const headerRect = headerContainer.getBoundingClientRect();
    return (
      window.innerWidth / 2 -
      headerRect.width / 2 +
      parseInt(window.getComputedStyle(headerContainer).paddingRight) -
      DRIFT_HORIZONTAL_PADDING +
      4
    );
  };

  const getVerticalOffset = () => {
    const ascendSticky = document.querySelector('div.ascend-sticky-reg')
    const ascendStickyContainer = document.querySelector('div.ascend-sticky-reg .container')

    if (!ascendSticky || !ascendSticky.classList.contains('ascend-sticky-reg--fixed')) {
      return DRIFT_VERTICAL_PADDING;
    }

    const ascendStickyRect = ascendStickyContainer.getBoundingClientRect();

    return ascendStickyRect.height + DRIFT_VERTICAL_PADDING;
  }

  const positionChat = () => {
    if (!window.drift || !chatOpen) {
      return;
    }

    window.drift.config({
      verticalOffset: getVerticalOffset(),
      horizontalOffset: getHorizontalOffset(),
    });
  };

  const onResize = () => {
    if (resizeTimeout) {
      return;
    }

    resizeTimeout = window.requestAnimationFrame(() => {
      setLoadingIconPosition();
      positionChat();
      resizeTimeout = null;
    });
  };

  const loadScript = async () => {
    return new Promise((resolve, reject) => {
      !(function () {
        const t = (window.driftt = window.drift = window.driftt || []);
        if (!t.init) {
          if (t.invoked)
            return void (
              window.console &&
              console.error &&
              console.error('Drift snippet included twice.')
            );
          (t.invoked = !0),
            (t.methods = [
              'identify',
              'config',
              'track',
              'reset',
              'debug',
              'show',
              'ping',
              'page',
              'hide',
              'off',
              'on',
            ]),
            (t.factory = function (e) {
              return function () {
                const n = Array.prototype.slice.call(arguments);
                return n.unshift(e), t.push(n), t;
              };
            }),
            t.methods.forEach(function (e) {
              t[e] = t.factory(e);
            }),
            (t.load = function (t) {
              const e = 3e5,
                n = Math.ceil(new Date() / e) * e,
                o = document.createElement('script');
              (o.type = 'text/javascript'),
                (o.async = !0),
                (o.crossorigin = 'anonymous'),
                (o.src = 'https://js.driftt.com/include/' + n + '/' + t + '.js');
              const i = document.getElementsByTagName('script')[0];
              i.parentNode.insertBefore(o, i);
            });
        }
      })();
      drift.SNIPPET_VERSION = '0.3.1';
      drift.load('vhhacgucu259');
      drift.on('ready', api => {
        resolve(api);
      });
    });
  };

  const showDrift = () => {
    window.drift.on('chatClose', () => {
      chatOpen = false;
      window.drift.api.widget.hide();
    });
    window.drift.api.startInteraction({ interactionId: DRIFT_INTERACTION_ID });
    window.drift.api.widget.show();
    chatOpen = true;
    positionChat();
  };

  let driftIcon = null;

  const setLoadingIconPosition = () => {
    if (!driftIcon) {
      return;
    }
    let adjustment = 12;

    driftIcon.style.bottom = `${getVerticalOffset() + 8}px`; // 42
    driftIcon.style.right = getHorizontalOffset() + adjustment + 'px'; // 202
  };

  const showLoadingIcon = () => {
    driftIcon = document.body.appendChild(document.createElement('div'));
    setLoadingIconPosition();
    driftIcon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.818 106.818">
       <g transform="translate(-73.591 -148.341)">
          <rect width="26.263" height="7" x="154.146" y="195.331" fill="#ffffff" fill-opacity="0.087" rx="6.911" ry="6.419"/>
          <rect width="26.263" height="7" x="36.256" y="231.802" fill="#ffffff" fill-opacity="0.17" rx="6.911" ry="6.419" transform="rotate(-30)"/>
          <rect width="26.263" height="7" x="-84.075" y="204.442" fill="#ffffff" fill-opacity="0.253" rx="6.911" ry="6.419" transform="rotate(-60)"/>
          <rect width="26.263" height="7" x="-174.604" y="120.581" fill="#ffffff" fill-opacity="0.336" rx="6.911" ry="6.419" transform="rotate(-90)"/>
          <rect width="26.263" height="7" x="-211.075" y="2.692" fill="#ffffff" fill-opacity="0.419" rx="6.911" ry="6.419" transform="rotate(-120)"/>
          <rect width="26.263" height="7" x="-183.715" y="-117.639" fill="#ffffff" fill-opacity="0.502" rx="6.911" ry="6.419" transform="rotate(-150)"/>
          <rect width="26.263" height="7" x="-99.854" y="-208.169" fill="#ffffff" fill-opacity="0.585" rx="6.911" ry="6.419" transform="scale(-1)"/>
          <rect width="26.263" height="7" x="18.035" y="-244.639" fill="#ffffff" fill-opacity="0.668" rx="6.911" ry="6.419" transform="rotate(150)"/>
          <rect width="26.263" height="7" x="138.366" y="-217.279" fill="#ffffff" fill-opacity="0.751" rx="6.911" ry="6.419" transform="rotate(120)"/>
          <rect width="26.263" height="7" x="228.896" y="-133.419" fill="#ffffff" fill-opacity="0.834" rx="6.911" ry="6.419" transform="rotate(90)"/>
          <rect width="26.263" height="7" x="265.366" y="-15.529" fill="#ffffff" fill-opacity="0.917" rx="6.911" ry="6.419" transform="rotate(60)"/>
          <rect width="26.263" height="7" x="238.006" y="104.802" fill="#ffffff" rx="6.911" ry="6.419" transform="rotate(30)"/>
       </g></svg>`;
    driftIcon.classList.add('drift-loading-icon');
  };

  const hideLoadingIcon = () => {
    if (!driftIcon) {
      return;
    }
    driftIcon.remove();
  };

  const init = () => {
    element.addEventListener('click', e => {
      if (window.drift && checkCookiePreference('targeting')) {
        showDrift();
      } else if (!window.drift && checkCookiePreference('targeting')) {
        showLoadingIcon();
        loadScript().then(api => {
          window.dataLayer.push({ event: 'drift-active' });
          hideLoadingIcon();
          showDrift();
        });
      } else if (element.dataset.popupOptions && PopupWithModules) {
        const options = JSON.parse(element.dataset.popupOptions);
        new PopupWithModules(element.dataset.driftPopup, {
          ...options,
          onLoad: popup => {
            popup.show();
          },
        });
      } else {
        // TODO: Call popup module, needs merging with normal popup module amends branch. WEBSITE-240
        return;
      }

      e.preventDefault();
      return false;
    });

    window.addEventListener('scroll', () => onResize());
    window.addEventListener('resize', () => onResize());
  };

  init();

  return {
    element,
  };
};

export default DriftChatbot;
