import { htmlToElement as el } from '../../../../_scripts/utils/dom';
import { NavAnalytics } from '../../../../_scripts/genericModules/navAnalytics';
import {
  checkAndSubmitMktoForm,
  destyleMktoForm,
  loadMarketoFormsJs,
} from '../../../../_scripts/utils/forms';

export const Newsletter = element => {
  const formInit = form => {
    const submitButton = element.querySelector('.mktoButton');
    const loadingText = element.dataset.nlLoadingText;
    const successText = element.dataset.nlSuccess;

    let errorMessage = null;

    const showSubmitting = () => {
      submitButton.innerText = loadingText;
      submitButton.disabled = true;
    };

    const fixLabel = el => {
      if (el.value) {
        element.querySelectorAll('label[for=' + el.id + ']')[0].classList.add('mktoValid');
      } else {
        element.querySelectorAll('label[for=' + el.id + ']')[0].classList.remove('mktoValid');
      }
    };

    const hideServerError = () => {
      if (errorMessage) {
        element.removeChild(errorMessage);
        errorMessage = null;
      }
    };

    const showComplete = () => {
      hideServerError();
      Array.from(element.children).forEach(child => {
        element.removeChild(child);
      });
      element.appendChild(
        el(`<p class="newsletter__message-success py-1 px-2">${successText}</p>`),
      );
      element.classList.add('newsletter--success');
    };

    const sendAnalytics = () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'emailSignup',
        signupLocation: 'Footer',
      });
    };

    const handleSubmit = () => {
      element.querySelectorAll("input[type='email']").forEach(el => {
        fixLabel(el);
      });
      showSubmitting();
      sendAnalytics();
      return false;
    };

    const handleEvents = form => {
      form.onSubmit(formObject => {
        handleSubmit();
        return false;
      });

      form.onSuccess((values, followUpUrl) => {
        showComplete();
        return false;
      });

      form.onValidate(valid => {
        if (valid) {
          checkAndSubmitMktoForm(form);
        }
      });

      MktoForms2.onFormRender(() => {
        destyleMktoForm(form);
      });

      window.addEventListener('resize', () => {
        destyleMktoForm(form);
      });

      element.querySelectorAll('input, textarea').forEach(el => {
        el.addEventListener('change', e => {
          fixLabel(el);
        });
      });
    };

    handleEvents(form);
  };

  const init = () => {
    loadMarketoFormsJs().then(() => {
      MktoForms2.loadForm('//pages.skydio.com', '784-TUF-591', '1282', formInit);
    });
  };

  init();

  return {};
};

export const Footer = element => {
  if (!element) {
    return;
  }

  let newsletter = null;
  const tracking = new NavAnalytics(element, 'footer');

  function handleIntersection(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const newsletterElement = element.querySelector('[data-nl]');
        if (newsletterElement) {
          newsletter = Newsletter(newsletterElement);
        }
        observer.disconnect();
      }
    });
  }

  const observerOptions = {
    root: null,
    rootMargin: '1000px',
    threshold: 0.0,
  };

  const observer = new IntersectionObserver(handleIntersection, observerOptions);
  observer.observe(element);

  return {
    newsletter,
  };
};
