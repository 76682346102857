const BackgroundImage = (el) => {
  const imageUrl = el.getAttribute("data-bg-image");
  const aspectRatio = (() => {
    try {
      return parseFloat(el.dataset.bgImageRatio);
    } catch {
      return 1;
    }
  })();

  const activateImage = () => {
    const width =
      el.offsetWidth / aspectRatio < el.offsetHeight
        ? el.offsetHeight * aspectRatio
        : el.offsetWidth;

    el.style.backgroundImage =
      "url('" + imageUrl + "?w=" + Math.floor(width * window.devicePixelRatio) + "&fm=webp')";
  };

  activateImage();
};

export default BackgroundImage;
