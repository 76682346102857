import { PopupWithModules } from './popupWithModules';
import { PopupWithScrollTrigger } from './popupWithScrollTrigger';


export const PopupModule = ({ parent }) => {
  const popups = {};

  if (window.popups && Array.isArray(window.popups)) {
    window.popups.forEach(options => {
      popups[options.id] = options.hookPercentage
        ? new PopupWithScrollTrigger(options.id, options)
        : new PopupWithModules(options.id, options);
    });
  }

  const buttons = parent.querySelectorAll('[data-popup-link]');

  buttons.forEach(btn => {
    const options = JSON.parse(btn.dataset.popupOptions);
    const popup = (popups[options.id] = new PopupWithModules(btn.dataset.popupLink, options));
    btn.addEventListener('click', (e) => {
      popup.show();
      e.preventDefault();
    });
  });

  return {
    popups
  };
};

export default PopupModule;
