import './utils/i18n';
import './utils/cookies';

import {initializeComponents} from './loadComponents';
import {Header} from '../_includes/layouts/inc/header/script/v2';
import './utils/popup';
import './genericModules/animateText';

window.addEventListener('load', () => {
  initializeComponents(document);

  window.dispatchEvent(new CustomEvent('jsModulesInitialized'));

  if (window.location.hash.length > 0) {
    const el = document.querySelector(window.location.hash);
    if (el) {
      window.requestAnimationFrame(() => {
        window.scrollTo(0, el.offsetTop);
      });
    }
  }
});
window.addEventListener('DOMContentLoaded', () => {
  const header = new Header(document.querySelector('[data-header]'));

  // const footer = Footer(document.querySelector('[data-footer]'));
});
