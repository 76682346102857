import { loadExternalJs } from '../../../_scripts/utils/helpers';

const VideoPlayer = (el, options = {}, callbacks = {}) => {
  if (!el) {
    return;
  }

  const videoObj = JSON.parse(el.getAttribute('data-video'));
  const videoElement = el.querySelector('video');
  const poster = el.getAttribute('data-poster');
  const posterImage = el.querySelector('.sanity-video__poster-image');
  const PlayButtonWrapper = el.querySelectorAll('.sanity-video__play-button-wrapper')[0];
  const overrides = el.dataset.videoOverrides ? JSON.parse(el.dataset.videoOverrides) : {};
  const plugins =
    videoObj?.title?.length > 0 && !overrides.plugins
      ? {
          mux: {
            debug: false,
            data: {
              env_key: MUX_ENV_KEY,
              video_id: videoObj.video.asset.assetId,
              video_title: videoObj.title,
              video_duration: videoObj.video.asset.data.duration,
              video_stream_type: 'on-demand',
            },
          },
        }
      : overrides.plugins || {};
  const videoOptions = {
    muted:
      overrides.forceMuted ?? (videoObj.muted || (videoObj.autoplay && videoObj.muted !== false)),
    autoplay: overrides.forceAutoplay ?? videoObj.autoplay,
    loop: overrides.forceLoop ?? videoObj.loop,
    controls: overrides.forceControls ?? videoObj.controls,
    autoload: overrides.forceAutoload ?? videoObj.autoload,
    bigPlayButton: false,
    poster: poster,
    ...(overrides.preload ? { preload: overrides.preload } : {}),
    ...options,
    plugins,
  };
  const analytics = {
    progress: 0,
    played: false,
    paused: false,
    lastProgress: null,
  };

  let thisVideo = null;
  let watchedRanges = [];
  let lastTime = 0; // Track the last time update time
  let isSeeking = false;

  const sendAnalytics = (action, value) => {
    if (!videoObj?.title || videoObj?.title?.length === 0) {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'video',
      action,
      title: videoObj.title,
      ...(value ? { value } : {}),
    });
  };

  const showPlayButton = () => {
    PlayButtonWrapper.classList.remove('sanity-video__play-button--hidden');
    PlayButtonWrapper.classList.add('sanity-video__play-button--visible');
  };

  const hidePlayButon = () => {
    PlayButtonWrapper.classList.add('sanity-video__play-button--hidden');
    PlayButtonWrapper.classList.remove('sanity-video__play-button--visible');
    if (posterImage) {
      posterImage.style.display = 'none';
    }
  };

  const consolidateRanges = () => {
    watchedRanges.sort((a, b) => a.start - b.start);
    let consolidated = [watchedRanges[0]];

    for (let i = 1; i < watchedRanges.length; i++) {
      let lastRange = consolidated[consolidated.length - 1];
      let currentRange = watchedRanges[i];
      if (currentRange.start <= lastRange.end) {
        lastRange.end = Math.max(lastRange.end, currentRange.end);
      } else {
        consolidated.push(currentRange);
      }
    }

    watchedRanges = consolidated;
  };

  const updateWatchedRanges = currentTime => {
    let tolerance = 0.5; // To handle minor inaccuracies in tracking time
    let updated = false;

    for (let range of watchedRanges) {
      if (currentTime >= range.start - tolerance && currentTime <= range.end + tolerance) {
        if (currentTime > range.end) range.end = currentTime;
        if (currentTime < range.start) range.start = currentTime;
        updated = true;
        break;
      }
    }

    if (!updated) {
      watchedRanges.push({ start: currentTime, end: currentTime });
    }

    consolidateRanges();
  };

  const calculateWatchedPercentage = () => {
    let watchedSeconds = 0;
    watchedRanges.forEach(range => (watchedSeconds += range.end - range.start));
    let totalSeconds = thisVideo.duration();
    return Math.round((watchedSeconds / totalSeconds) * 100);
  };

  const listenToEvents = () => {
    PlayButtonWrapper.addEventListener('click', e => {
      thisVideo.play();
      hidePlayButon();
    });

    thisVideo.on('pause', () => {
      showPlayButton();
    });

    thisVideo.on('playing', () => {
      hidePlayButon();
    });

    thisVideo.on('ended', () => {
      showPlayButton();
    });

    if (videoObj?.title && videoObj?.title?.length > 0) {
      let pauseTimeout = null;

      thisVideo.on('pause', () => {
        clearTimeout(pauseTimeout);

        pauseTimeout = setTimeout(() => {
          const percent = calculateWatchedPercentage(thisVideo.currentTime());
          sendAnalytics('pause', percent);
          pauseTimeout = null;
        }, 1000);
      });

      thisVideo.on('ended', () => {
        sendAnalytics('progress', 100);
      });

      thisVideo.on('seeking', () => {
        isSeeking = true;
      });

      thisVideo.on('seeked', () => {
        let seekTolerance = 1; // seconds

        if (Math.abs(lastTime - thisVideo.currentTime) > seekTolerance) {
          updateWatchedRanges(lastTime);
        }

        isSeeking = false;
      });

      thisVideo.on('timeupdate', () => {
        const percent = calculateWatchedPercentage();

        if (!isSeeking) {
          updateWatchedRanges(thisVideo.currentTime());
          lastTime = thisVideo.currentTime;
        }

        if (
          percent > analytics.progress &&
          percent > analytics.progress &&
          Math.floor(percent % 10) === 0
        ) {
          analytics.progress = percent;
          sendAnalytics('progress', percent);
        }
      });

      thisVideo.on('play', () => {
        if (pauseTimeout) {
          clearTimeout(pauseTimeout);
          return;
        }

        pauseTimeout = setTimeout(() => {
          const percent = calculateWatchedPercentage();
          sendAnalytics('play', percent);
          pauseTimeout = null;
        }, 1000);
      });
    }
  };

  const destroy = () => {
    thisVideo?.dispose();
  };

  const returnValue = {
    element: el,
    video: thisVideo,
    destroy,
  };

  const activateVideo = () => {
    loadExternalJs(MUX_VIDEO_SDK_URL)
      .then(() => {
        returnValue.video = thisVideo = videojs(videoElement, videoOptions);

        listenToEvents();

        if (callbacks.onActivate) {
          callbacks.onActivate(thisVideo);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  activateVideo();

  return returnValue;
};

export default VideoPlayer;
