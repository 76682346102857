import { CategoryNav, SocialShareCopyLink } from '../_includes/macros/articles/script';

import Analytics from './genericModules/analytics';
import AppCard from '../_includes/macros/appCard/script';
import BackgroundImage from '../_includes/macros/backgroundImage/script';
import ButtonVideo from '../_includes/macros/buttonVideo/script';
import { CaptionSlider } from '../_includes/modules/captionCarouselModule/script';
import CollapsibleContentGroups from './genericModules/collapsibleContentGroups';
import ContentSectionsWithModals from '../_includes/modules/contentSectionsWithModals/script';
import CountdownTimer from '../_includes/modules/countdownTimer/script';
import DebugOn from './utils/debug';
import FlexCarousel from '../_includes/modules/flexCarousel/script';
import ImageGallery from '../_includes/modules/imageGalleryModule/script';
import InitMarketoForm from '../_includes/modules/marketoFormModule/script';
import { Footer } from '../_includes/layouts/inc/footer/script';
import JobListingsModule from '../_includes/modules/jobListingsModule/script.js';
import Newsroom from './pages/newsroom';
import ParallaxCaptionSlider from '../_includes/modules/parallaxCaptionSlider/script';
import PopupModule from '../_includes/modules/popupModule/script';
import PullquoteCarousel from '../_includes/modules/pullquoteModule/script';
import ResponsiveSlider from '../_includes/macros/responsiveSlider/script';
import SecondaryNavV2 from '../_includes/modules/secondaryNavV2Module/script';
import SimpleImageCarousel from '../_includes/modules/simpleImageCarousel/script';
import Sketchfab from '../_includes/macros/sketchfab/script';
import UtmCatcher from './genericModules/utmCatcher';
import Video from '../_includes/macros/video/script';
import VideoModule from '../_includes/modules/videoModule/script';
import VideoThumbAndModal from '../_includes/macros/videoThumbAndModal/script';
import Animation from './genericModules/animation';
import ThreeSixtyModule from '../_includes/modules/threeSixtyModule/script';
import BasicGallery from './genericModules/basicGallery';
import DriftChatbot from './genericModules/driftChatBot.js';
import { PopupWithModules } from '../_includes/modules/popupModule/popupWithModules';
import DropInContentModule from '../_includes/modules/dropInContentModule/script';
import ButtonDropContent from '../_includes/macros/buttonDropContent/script';
import VariableContentCarousel from '../_includes/modules/variableContentCarouselModule/script';
import TimedVideoAndTextHero from '../_includes/modules/timedVideoAndTextHeroModule/script';
import X10Hero from '../_includes/modules/x10HeroModule/script';
import AscendHeroModule from '../_includes/modules/ascendHero/script';
import ascendStickyReg from '../_includes/modules/ascendStickyReg/script';
import { initializeContact } from './genericModules/contact';
import AnimatedHeadlineModule from '../_includes/modules/blockContentModule/script';
import SubscriptionManagement from '../_includes/modules/subscriptionManagementModule/script';
import TextHoverWallModule from '../_includes/modules/textHoverWall/script.js';
import AnimateText from './genericModules/animateText';

export const initializeComponents = (scope, selectorsOnly = false) => {
  const components = [
    {
      componentFunction: Sketchfab,
      selector: '[data-sketchfab]',
    },
    {
      componentFunction: Footer,
      selector: '[data-footer]',
    },
    {
      componentFunction: Animation,
    },
    {
      componentFunction: FlexCarousel,
      selector: '[data-flex-carousel]',
    },
    {
      componentFunction: BackgroundImage,
      selector: '[data-bg-image]',
      intersectionObserver: {
        threshold: 0,
      },
    },
    {
      componentFunction: JobListingsModule,
      selector: '[data-job-listings]',
    },
    {
      componentFunction: ResponsiveSlider,
      selector: '[data-responsive-slider]',
    },
    {
      componentFunction: Video,
      selector: '[data-video-player]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: '0px 0px 600px 0px',
      },
    },
    {
      componentFunction: CollapsibleContentGroups,
      selector: '[data-collapsible]',
    },
    {
      componentFunction: CaptionSlider,
      selector: '[data-cs]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: '0px 0px 200px 0px',
      },
    },
    {
      componentFunction: SimpleImageCarousel,
      selector: '[data-simple-image-carousel]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: '0px 0px 200px 0px',
      },
    },
    {
      componentFunction: ImageGallery,
      selector: '[data-ig]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: '0px 0px 200px 0px',
      },
    },
    {
      componentFunction: Newsroom,
      selector: '[data-newsroom]',
    },
    {
      componentFunction: VideoThumbAndModal,
      selector: '[data-video-modal]',
    },
    {
      componentFunction: CategoryNav,
      selector: '[data-category-nav]',
    },
    { 
      componentFunction: SecondaryNavV2,
      selector: '[data-sn]',
    },
    {
      componentFunction: PopupModule,
    },
    {
      componentFunction: ThreeSixtyModule,
      selector: '[data-three-sixty-embed]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: '1000px 0px 1000px 0px',
      },
    },
    {
      componentFunction: CountdownTimer,
      selector: '[data-countdown-timer]',
    },
    {
      componentFunction: VideoModule,
      selector: '[data-video-module]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: '200px 0px 200px 0px',
      },
    },
    {
      componentFunction: ParallaxCaptionSlider,
      selector: '[data-pcs]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: '200px 0px 200px 0px',
      },
    },
    {
      componentFunction: ContentSectionsWithModals,
      selector: '[data-cm]',
    },
    {
      componentFunction: SocialShareCopyLink,
      selector: '[data-social-copy]',
    },
    {
      componentFunction: Analytics,
    },
    {
      componentFunction: UtmCatcher,
    },
    {
      componentFunction: SubscriptionManagement,
      selector: '[data-subscription-management]',
    },
    {
      componentFunction: InitMarketoForm,
      selector: '[data-marketo-form]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: `0px 0px ${window.innerHeight * 3}px 0px`,
      },
    },
    {
      componentFunction: PullquoteCarousel,
      selector: '[data-pullquote-carousel]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: '0px 0px 600px 0px',
      },
    },
    {
      componentFunction: ButtonVideo,
    },
    {
      componentFunction: initializeContact,
    },
    {
      componentFunction: AscendHeroModule,
      selector: '[data-ascend-hero]',
    },
    {
      componentFunction: AnimatedHeadlineModule,
      selector: '[data-animated-headline]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: '200px 0px',
      },
    },
    {
      componentFunction: ascendStickyReg,
      selector: '[data-ascend-sticky-reg]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: '10000px 0px 300px 0px',
      },
    },
    {
      componentFunction: AppCard,
      selector: '[data-app-card]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: '0px 0px 300px 0px',
      },
    },
    {
      componentFunction: BasicGallery,
      selector: '[data-basic-gallery]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: '0px 0px 300px 0px',
      },
    },
    {
      componentFunction: element => DriftChatbot(element, PopupWithModules),
      selector: '[data-drift]',
    },
    {
      componentFunction: ButtonDropContent,
      selector: '[data-button-drop-content]',
    },
    {
      componentFunction: DropInContentModule,
      selector: '[data-drop-content-module]',
    },
    {
      componentFunction: VariableContentCarousel,
      selector: '[data-variable-content-carousel]',
    },
    {
      componentFunction: TimedVideoAndTextHero,
      selector: '[data-timed-video-and-text-hero]',
    },
    {
      componentFunction: X10Hero,
      selector: '[data-x10-hero]',
    },
    {
      componentFunction: TextHoverWallModule,
      selector: '[data-text-hover-wall]',
      intersectionObserver: {
        threshold: 0,
        rootMargin: '0px 0px 0px 0px',
      },
    },
    {
      componentFunction: AnimateText,
      selector: '[data-animate-text-delay]',
    },
  ];

  components.forEach(component => {
    scope.jsModules = scope.jsModules || [];

    if (!component.selector && !selectorsOnly) {
      const result = component.componentFunction({ parent: scope });
      if (result) scope.jsModules.push(result);
      return;
    }

    const elements = scope.querySelectorAll(component.selector);

    elements.forEach(element => {
      if (component.intersectionObserver) {
        let observer = null;

        observer = new IntersectionObserver(
          entries => {
            let calledInit = false;

            entries.forEach(entry => {
              if (calledInit) {
                return;
              }

              if (entry.intersectionRatio > 0) {
                scope.jsModules.push(component.componentFunction(element, { parent: scope }));
                observer.unobserve(element);
                calledInit = true;
              }
            });
          },
          {
            root: null,
            rootMargin: component.intersectionObserver.rootMargin ?? '0px',
            threshold: component.intersectionObserver.threshold ?? 0,
          },
        );

        observer.observe(element);
      } else {
        scope.jsModules.push(component.componentFunction(element, { parent: scope }));
      }
    });
  });

  window.addEventListener('keypress', DebugOn);
};

window._initializeComponents = initializeComponents;
