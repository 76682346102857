export const isIterable = input => {
  if (input === null || input === undefined) {
    return false;
  }

  return typeof input[Symbol.iterator] === 'function';
};

window.loadingJs = window.loadingJs || [];
window.loadedJs = window.loadedJs || [];

export const isJsLoaded = src => {
  return window.loadedJs.includes(src);
};

export const isLoadingJs = src => {
  return window.loadingJs.includes(src);
}

export const loadExternalJs = async src => {
  if (isJsLoaded(src)) {
    return Promise.resolve();
  }

  if (isLoadingJs(src)) {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (isJsLoaded(src)) {
          clearInterval(interval);
          resolve();
        }
      }, 50);
    });
  }

  window.loadingJs.push(src);

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = () => {
      window.loadedJs.push(src);
      window.loadingJs.splice(window.loadingJs.indexOf(src), 1);
      resolve();
    };
    script.onerror = () => {
      reject();
    };
    script.src = src;
    document.head.appendChild(script);
  });
};
