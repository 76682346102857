import VideoPlayer from '../../macros/video/script';
import { AnimateText } from '../../../_scripts/genericModules/animateText';

class TimedVideoAndTextHero {
  constructor(element) {
    this.container = element;
    this.text = element.querySelector('[data-animate-text]');
    this.videos = {};
    this.videoElements = {
      desktop: element.querySelector('.bg-media__media--desktop [data-video]'),
      mobile: element.querySelector('.bg-media__media--mobile [data-video]'),
    };

    this.timings = this.container.dataset.timedVideoAndTextHero.split(',').map(t => parseFloat(t));
    if (this.timings[0] !== 0) {
      this.timings.unshift(0);
    }

    this.text = new AnimateText(this.container.querySelector('[data-animate-text]'));
    this.currentSection = 0;
    this.init();
  }

  get video() {
    return this.videos[this._videoKey];
  }

  set video(value) {
    if (!value) {
      this._videoKey = null;
      return;
    }

    if (value !== this._videoKey) {
      if (this._videoKey) {
        this.videos[this._videoKey].video.pause();
      }

      if (!this.videos[value]) {
        this.videos[value] = VideoPlayer(
          this.videoElements[value],
          { muted: true, loop: true },
          { onActivate: this.onVideoActivate.bind(this) },
        );
      } else {
        this.videos[value].video.play();
      }
      this._videoKey = value;
    }
  }

  onVideoActivate(video) {
    video.on('timeupdate', this.onVideoTimeUpdate.bind(this));
    if (video.readyState >= 4) {
      video.play();
    } else {
      video.on('canplaythrough', () => {
        video.play();
      });
    }
    this.text.current = 0;
  }

  get currentSection() {
    return this._currentSection;
  }

  set currentSection(value) {
    if (value !== this._currentSection) {
      this._currentSection = value;
      this.text.current = value;
    }
  }

  onVideoTimeUpdate() {
    const currentTime = this.video.video?.currentTime();
    if (
      this.currentSection + 1 < this.timings.length &&
      currentTime > this.timings[this.currentSection + 1]
    ) {
      this.currentSection++;
    } else if (currentTime < this.timings[this.currentSection]) {
      let newSection = this.currentSection;
      for (let i = this.currentSection; i >= 0; i--) {
        if (currentTime < this.timings[i]) {
          newSection = i - 1;
        }
      }
      this.currentSection = newSection;
    }
  }

  setupVideo() {
    if (window.innerWidth < 768) {
      this.video = 'mobile';
    } else {
      this.video = 'desktop';
    }
  }

  init() {
    this.setupVideo();
    window.addEventListener('resize', this.setupVideo.bind(this));
  }
}

export default element => new TimedVideoAndTextHero(element);
