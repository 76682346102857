export const getBreakpoints = () => {
  const width = window.innerWidth;
  const breakpointXs = '0px';
  const breakpointSm = '576px';
  const breakpointMd = '768px';
  const breakpointLg = '1024px';
  const breakpointXl = '1280px';

  return {
    xs: window.matchMedia(`(max-width: ${parseInt(breakpointXs, 10) - 1}px)`).matches,
    sm: window.matchMedia(`(max-width: ${parseInt(breakpointSm, 10) - 1}px)`).matches,
    md: window.matchMedia(`(max-width: ${parseInt(breakpointMd, 10) - 1}px)`).matches,
    lg: window.matchMedia(`(max-width: ${parseInt(breakpointLg, 10) - 1}px)`).matches,
    xl: window.matchMedia(`(max-width: ${parseInt(breakpointXl, 10) - 1}px)`).matches,
  };
};