import ScrollMagic from "scrollmagic";
import ProgressBar from "../../macros/progressBar/script";

const Slide = (caption, media) => {
  let progressBar = null;
  const progressBarElement = caption.querySelector("[data-progress]");

  const progress = (value) => {
    progressBar?.progress(value);
  };

  const setActive = (active) => {
    if (active) {
      caption.classList.add("parallax-slider__caption--active");
      media.classList.add("parallax-slider__media-item--active");
    } else {
      caption.classList.remove("parallax-slider__caption--active");
      media.classList.remove("parallax-slider__media-item--active");
    }
  };

  const init = () => {
    if (progressBarElement) {
      progressBar = ProgressBar(progressBarElement, {
        vertical: true,
        playing: false,
        duration: 3,
      });
    }
  };

  init();

  return {
    progress,
    setActive,
  };
};

const ParallaxCaptionSlider = (element) => {
  const slides = [];
  const fullscreen = element.dataset.pcs === "fullscreen";
  const container = element.querySelector("[data-pcs-container]");
  const pin = element.querySelector("[data-pcs-pin]");
  const grid = element.querySelector("[data-pcs-grid]");
  const hasInitialMedia = element.dataset.pcsInitialMedia === "true";

  let currentProgress = 0;
  let isMobileView = false;
  let scrollController = null;
  let scrollScene = null;
  let resizeThrottle = null;

  const progress = (value) => {
    // If there's an initial media item set we need to move the slides on by one if
    // there is any progress made to the slider at all.
    const initialMediaShift = value > 0 && hasInitialMedia ? 1 : 0;
    const length = slides.length - initialMediaShift;
    const lengthPerSlide = 1 / length;

    let currentSlideIndex = Math.floor(length * value) + initialMediaShift;

    if (currentSlideIndex >= slides.length) {
      currentSlideIndex = slides.length - 1;
    }

    const slideProgress = (value - (currentSlideIndex - initialMediaShift) * lengthPerSlide) / lengthPerSlide;

    for (let i = 0; i < slides.length; i++) {
      slides[i].progress(currentSlideIndex === i ? slideProgress : currentSlideIndex < i ? 0 : 1);
      slides[i].setActive(i === currentSlideIndex);
    }

    currentProgress = value;
  };

  const createSlides = () => {
    slides.length = 0;

    const captions = Array.from(element.querySelectorAll("[data-pcs-caption]"));
    const media = Array.from(element.querySelectorAll("[data-pcs-media]"));

    slides.push(...captions.map((c, i) => Slide(c, media[i])));
  };

  const updateIsMobileView = () => {
    isMobileView = window.innerWidth < 1024;
  };

  const moveMedia = () => {
    const mediaContainer = element.querySelector("[data-pcs-media-container]");

    if (isMobileView && fullscreen && mediaContainer.parentElement === pin) {
      grid.append(mediaContainer);
      element.classList.remove("parallax-slider--fs-images");
    } else if (!isMobileView && fullscreen && mediaContainer.parentElement === grid) {
      pin.prepend(mediaContainer);
      element.classList.add("parallax-slider--fs-images");
    }
  };

  const setUpSlides = () => {
    const isCurrentlyMobile = isMobileView;
    updateIsMobileView();

    if (isCurrentlyMobile !== isMobileView) {
      moveMedia();
    }

    createSlides();
    progress(currentProgress);
  };

  const createScrollMagic = () => {
    if (scrollController) {
      destroy();
    }

    scrollController = new ScrollMagic.Controller();
    scrollScene = new ScrollMagic.Scene({
      triggerElement: container,
      duration: slides.length * 1000,
      triggerHook: 0,
      offset: 0,
    })
      .setPin(pin)
      .on("progress", (value) => progress(value.progress))
      .addTo(scrollController);
  };

  const destroy = () => {
    scrollController.destroy(true);
    scrollScene.destroy(true);
  };

  const resize = () => {
    if (resizeThrottle) {
      clearTimeout(resizeThrottle);
    }

    resizeThrottle = setTimeout(() => {
      setUpSlides();
      createScrollMagic();
      resizeThrottle = null;
    }, 200);
  };

  const init = () => {
    setUpSlides();
    createScrollMagic();
    window.addEventListener("resize", () => resize());
  };

  init();

  return {
    destroy,
  };
};

export default ParallaxCaptionSlider;
