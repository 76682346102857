import SketchfabAPI from "@sketchfab/viewer-api/viewer-api";

const Sketchfab = (element) => {
  const iframe = element.querySelector("iframe");
  let client = null;
  const modelId = element.dataset.sketchfabId;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  let viewerApi = null;

  const viewerReady = () => {};

  const stopViewer = (entries, observer) => {
    if (!isMobile) {
      return;
    }

    entries.forEach((entry) => {
      if (!entry.isIntersecting && (viewerApi || client)) {
        viewerApi?.stop();
        viewerApi = null;
        client = null;
        iframe.src = "";
      }
    });
  };

  const initViewer = () => {
    if (viewerApi) {
      return;
    }
    client = new SketchfabAPI(iframe);
    client.init(modelId, {
      success: (api) => {
        viewerApi = api;
        viewerApi.start();
        viewerApi.addEventListener("viewerready", viewerReady);
      },
    });
  };

  const loadViewer = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        initViewer();
      }
    });
  };

  const init = () => {
    const observer = new IntersectionObserver((entries, observer) => {
      loadViewer(entries, observer);
      stopViewer(entries, observer);
    });

    // Observe the viewer container
    observer.observe(iframe);
  };

  const error = (e) => {
    console.error("Sketchfab Viewing API Error - ", e);
  };

  init();
};

export default Sketchfab;
