import Swiper, { A11y, Autoplay, Keyboard, Navigation, Pagination } from 'swiper';

class BasicGallery {
  constructor(element) {
    this.element = element;
    this.options =
      element.dataset.basicGallery == '' ? {} : JSON.parse(element.dataset.basicGallery);
    this.init();
  }

  init() {
    this.swiper = new Swiper(this.element, {
      slidesPerView: 1,
      spaceBetween: 0,
      effect: 'slide',
      speed: 1000,
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
        clickable: true,
      },
      on: {
        slideChange: function () {
          const videos = this.el.querySelectorAll('video');
          if (videos.length > 0) {
            videos.forEach(video => {
              video.pause();
            });
          }
        },
      },
      ...this.options,
      modules: [A11y, Navigation, Autoplay, Pagination, Keyboard],
    });
  }
}

export default element => new BasicGallery(element);
