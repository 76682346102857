import {htmlToElement as el} from "../../../_scripts/utils/dom";
import Modal from "../modal/script";

const ContentSectionsWithModals = (element) => {
  const sections = Array.from(element.querySelectorAll("[data-cm-section]"));

  const initializeSection = (section) => {
    const button = section.querySelector("[data-cm-open]");
    const content = section.querySelector("[data-cm-modal]");

    button.addEventListener("click", () => {
      Modal(el(content.children[0].outerHTML), "inner", {
        show: true,
        classes: "content-modals__modal-inner",
        outerClasses: "content-modals__modal-outer",
        fadeIn: true,
        destroyOnClose: true,
      });
    });
  };

  const init = () => {
    sections.forEach((s) => initializeSection(s));
  };

  init();

  return {};
};

export default ContentSectionsWithModals;
