import gsap from 'gsap';

class AscendHero {
  constructor(element) {
    this.container = element;
    this.titles = this.container.querySelectorAll('.ascend-hero__title-part');
    this.buttonContainer = this.container.querySelector('.ascend-hero__buttons');
    this.subtitle = this.container.querySelector('.ascend-hero__subtitle');
    this.init();
  }

  init() {
    this.tl = gsap.timeline();

    const floatInArray = Array.from(this.titles);

    this.tl
      .to(floatInArray, {
        stagger: {
          each: 0.5,
          onStart: function (args) {
            this.targets()[0].classList.add('ascend-hero__title-part--end');
          },
        },
      })
      .to(this.subtitle, {
        delay: 0.75,
        duration: 0.75,
        onStart: function (args) {
          this.targets()[0].classList.add('ascend-hero__title-part--end');
        },
      })
      .to(this.buttonContainer, {
        delay: 0.3,
        duration: 0.75,
        onStart: function (args) {
          this.targets()[0].classList.add('ascend-hero__title-part--end');
        },
      });
  }
}

const AscendHeroModule = element => {
  return {
    ascendHero: new AscendHero(element),
  };
};

export default AscendHeroModule;
