export const checkCookiePreference = (preference) => {
  let cookiePreference = localStorage.getItem("cookie-preference");
  let cookiePreferenceObject = cookiePreference ? JSON.parse(cookiePreference) : null;
  return cookiePreferenceObject && cookiePreferenceObject[preference] === true;
}

export const setCookiePreference = (preference, value) => {
  const cookiePreference = localStorage.getItem("cookie-preference");
  const cookiePreferenceObject = cookiePreference ? JSON.parse(cookiePreference) : {};
  cookiePreferenceObject[preference] = value;
  if (cookiePreferenceObject.targeting) {
    window.mutiny.client.optIn()
  }
  localStorage.setItem("cookie-preference", JSON.stringify(cookiePreferenceObject));
}

window.setCookiePreference = setCookiePreference;
window.checkCookiePreference = checkCookiePreference;