const { throttle } = require('../../_utils/events');

class Animation {
  constructor(element) {
    const offsetY = element.offsetTop;
    this._startingOffsetY = offsetY;
    this.element = element;
    this.addEventListeners();
    this.onScroll();
  }

  get startAtPercent() {
    return 100 - parseInt(this.element.dataset.animStart ?? 0);
  }

  get endAtPercent() {
    return 100 - parseInt(this.element.dataset.animEnd ?? 100);
  }

  get yPosition() {
    return this.element.parentElement.getBoundingClientRect().top + this._startingOffsetY;
  }

  addEventListeners() {
    window.addEventListener('scroll', throttle(this.onScroll.bind(this), 50));
  }

  updatePosition(progress) {
    throw new Error('updatePosition() not implemented');
  }

  onScroll() {
    const progress = this.calculateProgress();
    this.updatePosition(progress);
  }

  calculateProgress() {
    const viewportHeight = window.innerHeight;

    /**
     * Calculate the height scroll offset based on the element's height and
     * viewport height. If the element extends beyond the viewport, the height
     * scroll offset is calculated to adjust the animation progress.
     */
    let heightScrollOffset = 0;
    if (this.element.offsetHeight - viewportHeight > 0) {
      heightScrollOffset =
        (this.element.offsetHeight - viewportHeight) * (1 - this.yPosition / viewportHeight);
    }

    const scrollPercent = ((this.yPosition + heightScrollOffset) / viewportHeight) * 100;

    if (scrollPercent <= this.startAtPercent && scrollPercent >= this.endAtPercent) {
      return (scrollPercent - this.startAtPercent) / (this.endAtPercent - this.startAtPercent);
    } else if (scrollPercent < this.startAtPercent) {
      return 1;
    } else {
      return 0;
    }
  }
}

class LinearAnimation extends Animation {
  constructor(element) {
    super(element);
    this.yPosition = this.element.getBoundingClientRect().top;
  }

  get initialOffset() {
    return (parseInt(this.element.dataset.animOffset ?? 100) / 100) * this.element.offsetHeight;
  }

  get initialOpacity() {
    return (
      parseInt(
        this.element.dataset.animOpacity === undefined ? 100 : this.element.dataset.animOpacity,
      ) / 100
    );
  }

  get initialBlur() {
    return parseInt(this.element.dataset.animBlur ?? 0);
  }

  get yPosition() {
    // As we are transforming the element, we need to take the offset into account
    this._yPosition =
      this.element.parentElement.getBoundingClientRect().top + this._startingOffsetY;

    return this._yPosition;
  }

  set yPosition(value) {
    this._yPosition = value;
  }

  set offset(value) {
    if (value !== this._offset) {
      this._offset = value;
      this.element.style.setProperty('--anim-offset-y', `${this._offset}px`);
    }
  }

  get offset() {
    return this._offset ?? 0;
  }

  set opacity(value) {
    if (value !== this._opacity) {
      this._opacity = value;
      this.element.style.setProperty('--anim-opacity', this._opacity);
    }
  }

  set blur(value) {
    if (value !== this._blur) {
      this._blur = value;
      this.element.style.setProperty('--anim-blur', `${this._blur}px`);
    }
  }

  get blur() {
    return this._blur ?? 0;
  }

  get opacity() {
    return this._opacity ?? 1;
  }

  updatePosition(progress) {
    this.offset = this.initialOffset * (1 - progress);
    if (this.initialOpacity !== 1) {
      this.opacity = 1 - (1 - this.initialOpacity) * (1 - progress);
    }
    if (this.initialBlur !== 1) {
      this.blur = this.initialBlur * (1 - progress);
    }
  }
}

class LinearStepAnimation extends LinearAnimation {
  constructor(element) {
    super(element);
    this.initialPosition();
    this.steps = Array.from(element.querySelectorAll('[data-anim-step]'));
  }

  initialPosition() {
    if (this.initialOpacity !== 1) {
      this.opacity = 1 - (1 - this.initialOpacity);
    }
    if (this.initialBlur !== 1) {
      this.blur = this.initialBlur;
    }
  }

  updatePosition(progress) {
    if (this.steps && this.steps.length > 0) {
      // divide the progress by the number of steps so that one is active at a time
      const stepProgress = progress * this.steps.length;
      this.steps.forEach((step, index) => {
        if (stepProgress > index && stepProgress < index + 1) {
          step.classList.add('active');
        } else {
          step.classList.remove('active');
        }
      });
    }
  }
}

export default () => {
  const easeAnimations = document.querySelectorAll("[data-anim='linear']");
  easeAnimations.forEach(element => {
    new LinearAnimation(element);
  });

  const easeStepAnimations = document.querySelectorAll("[data-anim='linear-step']");
  easeStepAnimations.forEach(element => {
    new LinearStepAnimation(element);
  });
};
