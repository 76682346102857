import Swiper, { A11y, Autoplay, Keyboard, Navigation, Pagination } from "swiper";
import VideoPlayer from "../../macros/video/script";

const FlexCarousel = (element) => {
  const swiperContainer = element.querySelector(".swiper");
  const autoplay = element.dataset.flexCarouselDelay && element.dataset.flexCarouselDelay != 0
    ? {
        delay: parseInt(element.dataset.flexCarouselDelay) * 1000,
        disableOnInteraction: true,
      }
    : false;

  const sliderOptions = {
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 0,
    autoplay,
    loop: true,
    pagination: {
      clickable: true,
      el: ".swiper-pagination",
    },
    effect: "slide",
    speed: 1000,
  };
  let slider = null;

  const destroy = () => {
    if (slider) {
      slider.destroy();
      slider = null;
    }
  };

  const initializeVideoComponents = () => {
    Array.from(
      swiperContainer.querySelectorAll(".swiper-slide .flex-content__media .sanity-video")
    ).forEach((el) => {
      el._video = VideoPlayer(el);
    });
  };

  const init = () => {
    slider = new Swiper(swiperContainer, {
      ...sliderOptions,
      on: {
        init: () => initializeVideoComponents(),
      },
      modules: [A11y, Navigation, Autoplay, Pagination, Keyboard],
    });
  };

  init();

  return {
    destroy,
  };
};

export default FlexCarousel;
