import Swiper, { A11y, Autoplay, Keyboard, Navigation, Pagination } from "swiper";

const PullquoteCarousel = (element) => {
  const swiperContainer = element.querySelector(".swiper");
  const sliderOptions = {
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 16,
    breakpoints: {
      1024: { slidesPerView: 1.5, spaceBetween: 32 },
    },
    autoplay: {
      delay: 12000,
      disableOnInteraction: true,
    },
    loop: true,
    pagination: {
      clickable: true,
      el: ".swiper-pagination",
    },
    navigation: {
      prevEl: ".gradient-left",
      nextEl: ".gradient-right",
    },
    effect: "slide",
    speed: 1000,
  };
  let slider = null;

  const destroy = () => {
    if (slider) {
      slider.destroy();
      slider = null;
    }
  };

  const init = () => {
    slider = new Swiper(swiperContainer, {
      ...sliderOptions,
      modules: [A11y, Navigation, Autoplay, Pagination, Keyboard],
    });
  };

  init();

  return {
    destroy,
  };
};

export default PullquoteCarousel;
