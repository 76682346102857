import { htmlToElement as el, htmlToElements as els } from "../../../_scripts/utils/dom";

const Modal = (content, type = "normal", options = {}) => {
  let innerContent = typeof content == "string" ? els(content) : content;
  let modalElement = el(`<div class="v2 modal hidden ${
    options.fadeIn ? "modal--fade-in" : ""
  } modal--${type} ${options.outerClasses ?? ""}">
      <div class="modal__inner ${options.classes ? options.classes : ""}">
      <button class="modal__close button button--tertiary button--icon"> 
      <span class="button__inner"> 
        <span class="button__icon"> 
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.832 11.7527L3.53906 4.45977L4.4583 3.54053L11.7512 10.8334C12.3956 11.4778 12.3956 12.5225 11.7512 13.1669L4.4583 20.4598L3.53906 19.5405L10.832 12.2476C10.9686 12.111 10.9686 11.8893 10.832 11.7527ZM13.9583 10.9598L20.4583 4.45977L19.5391 3.54053L13.0391 10.0405L13.9583 10.9598ZM20.4583 19.5405L13.9583 13.0405L13.0391 13.9598L19.5391 20.4598L20.4583 19.5405Z" fill="#22211F"/>
          </svg>
        </span> 
        ${window.translations?.close ?? "Close"}
      </span> 
    </button>
      </div>
    </div>`);
  const modalInner = modalElement.querySelector(".modal__inner");

  const bindEvents = () => {
    const close = modalElement.querySelector(".modal__close");

    close.addEventListener("click", () => {
      hide();

    });
  };

  const addContentToModal = () => {
    const inner = modalElement.querySelector(".modal__inner");
    inner.appendChild(innerContent);
  };

  const show = () => {
    modalElement.classList.remove("hidden");
    document.body.classList.add("modal--open");

    if (options.fadeIn) {
      setTimeout(() => modalElement.classList.add("modal--fade-in-show"));
    }

    modalInner.addEventListener("click", (e) => {
      e.stopPropagation();
    });
    modalElement.addEventListener("click", (e) => {
      hide();
    });
  };

  const hide = () => {
    modalElement.classList.add("hidden");
    document.body.classList.remove("modal--open");
    if (options.destroyOnClose) {
      destroy();
    }
    if (options.onClose) {
      options.onClose();
    }
  };

  const init = () => {
    document.body.appendChild(modalElement);
    bindEvents();
    addContentToModal();
    if (options.show) {
      show();
    }
  };

  const destroy = () => {
    document.body.removeChild(modalElement);
    innerContent = null;
    modalElement = null;
  };

  init();

  return {
    show,
    hide,
    destroy,
    container: modalElement,
  };
};

export default Modal;
