import Swiper, { EffectFade, Navigation, Pagination } from "swiper";
import ProgressBar from "../../macros/progressBar/script";
import { getBreakpoints } from "../../../_scripts/utils/breakpoints";
import VideoPlayer from "../../macros/video/script";

const CaptionSwiper = (element, vertical = true, opts = {}, callbacks = {}) => {
  const progressBarElements = Array.from(element.querySelectorAll("[data-progress]"));
  const direction = element.dataset.csCaptionsDisplay;
  const progressBars = [];

  let options = {
    grabCursor: element.dataset.grabCursor === "true",
    slidesPerView: vertical ? 4 : 1,
    centeredSlides: !vertical,
    spaceBetween: vertical ? null : 50,
    direction: direction ?? (vertical ? "vertical" : "horizontal"),
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    autoplay: false,
    ...opts,
  };

  let swiper = null;

  const setActiveSlide = (index, time) => {
    if (progressBars.length > 0) {
      progressBars[index].setDuration(time);
    }
    swiper.slides.forEach((el) => el.firstElementChild.classList.remove("is-active"));
    swiper.slides[index].firstElementChild.classList.add("is-active");
    swiper.slideTo(index);
  };

  const changeSlide = (index) => {
    if (callbacks.onChangeSlide) {
      callbacks.onChangeSlide(index);
    }
  };

  const playSlide = (index) => {
    if (progressBars.length > 0) {
      progressBars[index].play();
    }
  };

  const pauseSlide = (index) => {
    if (progressBars.length > 0) {
      progressBars[index].stop();
    }
  };

  const init = () => {
    swiper = new Swiper(element, {
      ...options,
      modules: [Navigation, Pagination],
    });

    progressBarElements.forEach((b) => {
      progressBars.push(ProgressBar(b, { vertical: vertical, playing: false, duration: 3 }));
    });

    swiper.slides.forEach((slide, index) => {
      slide.firstElementChild.addEventListener("click", () => changeSlide(index));
    });
  };

  const destroy = () => {
    progressBars.forEach((b) => b.destroy());
    swiper.destroy();
  };

  init();

  return {
    element,
    swiper,
    playSlide,
    pauseSlide,
    destroy,
    setActiveSlide,
  };
};

const SlideSwiper = (element, vertical = true, opts = {}, callbacks = {}) => {
  let options = {
    grabCursor: true,
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 30,
    direction: "horizontal",
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    autoplay: false,
    ...opts,
  };

  const videoWrappers = element.querySelectorAll(".sanity-video");
  let videos = [];
  let swiper = null;
  let currentSlide = 0;
  let videoTrigger = null;

  const destroy = () => {};

  const stopVideo = (index) => {
    videos[index].trigger("pause");
    videos[index].trigger("stop");
    videos[index].currentTime(0);
  };

  const stopCurrentSlideVideo = () => {
    stopVideo(currentSlide);
  };

  const playCurrentSlideVideo = () => {
    try {
      videos[currentSlide].currentTime(0);
      videos[currentSlide].play();
    } catch (ex) {
      console.warn(`Could not start video ${currentSlide}`);
    }
  };

  const getActiveSlideDuration = () => {
    try {
      return Math.round(videos[swiper.activeIndex].duration() * 10) / 10;
    } catch (ex) {
      console.warn(`Could not get time for player with index ${swiper.activeIndex}`);
      return 5;
    }
  };

  const playSlide = (startVideo = true) => {
    if (callbacks.onChangeSlide) {
      callbacks.onChangeSlide(swiper.activeIndex, getActiveSlideDuration());
    }

    if (currentSlide !== swiper.activeIndex) {
      stopCurrentSlideVideo();
      currentSlide = swiper.activeIndex;
    }

    if (startVideo === true) {
      playCurrentSlideVideo();
    }
  };

  const changeSlide = (index) => {
    swiper.slideTo(index);
  };

  const nextSlide = () => {
    if (swiper.activeIndex + 1 === videos.length) {
      swiper.slideTo(0);
    } else {
      swiper.slideNext();
    }
  };

  const initEventsForVideo = (index, video) => {
    video.on("play", () => {
      if (callbacks.onSlidePlay && currentSlide === index) {
        callbacks.onSlidePlay(index);
      }
    });

    video.on("ended", () => {
      if (callbacks.onSlidePlay && currentSlide === index) {
        nextSlide();
      }
    });

    video.on("stop", () => {
      if (callbacks.onSlidePause && currentSlide === index) {
        callbacks.onSlidePause(index);
      }
    });
    video.on("pause", () => {
      if (callbacks.onSlidePause && currentSlide === index) {
        callbacks.onSlidePause(index);
      }
    });
  };

  const activateVideo = (index, video) => {
    const completeActivation = () => {
      initEventsForVideo(index, video);

      if (index === swiper.activeIndex) {
        playSlide(false);
      }
    };

    videos[index] = video;

    if (!video.isReady_) {
      video.one("loadedmetadata", completeActivation);
    } else {
      completeActivation();
    }
  };

  const initializeVideos = () => {
    videoTrigger.unobserve(element);

    videos = Array.from(videoWrappers).map((v) => null);

    Array.from(videoWrappers).map((v, i) => {
      VideoPlayer(
        v,
        {
          loop: false,
          autoplay: false,
          autoload: true,
        },
        {
          onActivate: (video) => {
            activateVideo(i, video);
          },
        }
      );
    });
  };

  const init = () => {
    swiper = new Swiper(element, {
      ...options,
      on: {
        slideChange: () => playSlide(true),
      },
      modules: [EffectFade, Navigation, Pagination],
    });

    videoTrigger = new IntersectionObserver(initializeVideos, {
      root: element,
      rootMargin: "0px",
      threshold: 1,
    });

    videoTrigger.observe(element);
  };

  init();

  return {
    element,
    swiper,
    changeSlide,
    destroy,
  };
};

export const CaptionSlider = (
  element,
  captionSwiperOptions = {},
  slideSwiperOptions = {},
  { varyDirectionForMobile = true, direction = "vertical" } = {}
) => {
  let slideSwiper = null;
  let captionSwiper = null;
  let vertical = direction === "vertical";

  const createSliders = () => {
    slideSwiper = SlideSwiper(
      element.querySelector("[data-cs-slides]"),
      vertical,
      { ...slideSwiperOptions },
      {
        onChangeSlide: (index, time) => {
          captionSwiper.setActiveSlide(index, time);
        },
        onSlidePlay: (index) => {
          captionSwiper.playSlide(index);
        },
        onSlidePause: (index) => {
          captionSwiper.pauseSlide(index);
        },
      }
    );

    captionSwiper = CaptionSwiper(
      element.querySelector("[data-cs-captions]"),
      vertical,
      { ...captionSwiperOptions },
      {
        onChangeSlide: (index) => {
          slideSwiper.changeSlide(index);
        },
      }
    );
  };

  const destroySliders = () => {
    slideSwiper.destroy();
    captionSwiper.destroy();
  };

  const resize = () => {
    const breakpoints = getBreakpoints();

    if (vertical === breakpoints.lg && varyDirectionForMobile) {
      vertical = !breakpoints.lg;
      destroySliders();
      createSliders();
    }
  };

  const init = () => {
    vertical = varyDirectionForMobile ? !getBreakpoints().lg : direction === "vertical";

    createSliders();

    window.addEventListener("resize", resize);
  };

  const destroy = () => {
    window.removeEventListener("resize", resize);
  };

  init();

  return {
    element,
    slideSwiper,
    captionSwiper,
    destroy,
  };
};

export default CaptionSlider;
