const Newsroom = (element) => {
  const moreItems = element.querySelector("[data-newsroom-more]");
  const moreButton = element.querySelector("[data-newsroom-more-button]");

  const toggleMore = () => {
    moreItems.classList.toggle("hidden");

    if (moreItems.classList.contains("hidden")) {
      moreButton.innerHTML = moreButton.dataset.newsroomMoreText;
    } else {
      moreButton.innerHTML = moreButton.dataset.newsroomLessText;
    }
  };

  const init = () => {
    moreButton?.addEventListener("click", toggleMore)
  };

  init();
};

export default Newsroom;