export const scrollToAnchor = (event, anchor) => {
  event.preventDefault();
  const rect = document.querySelector(anchor).getBoundingClientRect();
  const scrollTop = window.pageYOffset;

  window.scroll({
    top: rect.top + scrollTop,
    behavior: "smooth",
  });

  window.history.replaceState(null, null, anchor);
};

(() => {
  // Don't tree-shake me list
  scrollToAnchor;
})();

export const getScrollPositionAsPercentage = () => {
  const h = document.documentElement,
    b = document.body,
    st = 'scrollTop',
    sh = 'scrollHeight';
  return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
}