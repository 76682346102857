import { SubNavigation } from './subNavigation';
import { MobileNavigationItem } from './mobileNavigationItem';
import { NavigationItem } from './navigationItem';

export class Navigation {
  constructor(element) {
    this.container = element;
    this.modes = {
      MOBILE: 'mobile',
      DESKTOP: 'desktop',
    };
    this.mode = this.modes.DESKTOP;

    this.subNavigationContainer = this.container.querySelector('[data-sub-nav-container]');
    this.navigationItems = {};
    this.mobileNavigationItems = {};
    this.subNavigation = null;

    this.init();
  }

  init() {
    // Desktop Navigation MainMenu
    Array.from(this.container.querySelectorAll('[data-nav] [data-nav-button]')).forEach(el =>
      this.addNavigationItem(el),
    );

    // Mobile Navigation Main Menu
    Array.from(this.subNavigationContainer.querySelectorAll('[data-nav-button]')).forEach(el =>
      this.addMobileNavigationItem(el),
    );

    this.subNavigation = new SubNavigation(this.subNavigationContainer, {
      mouseOver: key => (this.mode === this.modes.DESKTOP ? this.show(key) : null),
      mouseOut: key => (this.mode === this.modes.DESKTOP ? this.hide(key) : null),
    });

    Array.from(this.subNavigationContainer.querySelectorAll('[data-sub-nav-mobile-back]')).forEach(
      el => {
        el.addEventListener('click', () => this.show('main'));
      },
    );

    this.adjustForDeviceWidth();
    window.addEventListener('resize', () => this.adjustForDeviceWidth());
    document.addEventListener('keydown',(event) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        this.subNavigation.hide();
      }
    });
  }

  addMobileNavigationItem(element) {
    const mobileNavItem = new MobileNavigationItem(element, {
      show: key => this.show(key),
      hide: key => this.hide(key),
    });
    this.mobileNavigationItems[element.dataset.navButton] = mobileNavItem;
    mobileNavItem.on('click', () => this.show(element.dataset.navButton));
  }

  addNavigationItem(element) {
    this.navigationItems[element.dataset.navButton] = new NavigationItem(element, {
      show: key => this.show(key),
      hide: key => this.hide(key),
    });
  }

  show(key) {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    if (!key) {
      return;
    }

    if (this.navigationItems[this.subNavigation.current?.key]) {
      this.navigationItems[this.subNavigation.current.key].active = false;
    }

    if (this.navigationItems[key]) {
      this.navigationItems[key].active = true;
    }

    this.subNavigation.show(key);
  }

  set maxSubNavHeight(value) {
    this.subNavigation.maxHeight = value;
  }

  get maxSubNavHeight() {
    return this.subNavigation.maxHeight;
  }

  hideSubNavigation() {
    Object.keys(this.navigationItems).forEach(k => {
      this.navigationItems[k].active = false;
    });
    this.subNavigation.hide();
  }

  hideDesktop() {
    this.timeout = setTimeout(() => {
      this.hideSubNavigation();
      this.timeout = null;
    }, 300);
  }

  hideMobile() {
    this.hideSubNavigation();
  }

  hide() {
    if (this.mode === this.modes.DESKTOP) {
      this.hideDesktop();
    } else {
      this.hideMobile();
    }
  }

  adjustForDeviceWidth() {
    const currentMode = this.mode;
    this.mode = window.innerWidth < 1024 ? this.modes.MOBILE : this.modes.DESKTOP;

    if (currentMode !== this.mode) {
      this.hide();
    }
  }
}
