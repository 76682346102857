import { htmlToElement } from '../../../_scripts/utils/dom';

const ThreeSixtyModule = element => {
  let el = null;

  try {
    el = htmlToElement(element.dataset.threeSixtyEmbed);
  } catch (error) {
    console.error(error);
  }

  if (!el) {
    return;
  }

  const iframe = document.createElement('iframe');

  iframe.setAttribute('allow', 'xr-spatial-tracking; gyroscope; accelerometer');
  iframe.setAttribute('allowfullscreen', '');
  iframe.setAttribute('frameborder', '0');
  iframe.setAttribute('scrolling', 'no');
  iframe.setAttribute('width', '100%');
  iframe.setAttribute('height', '790px');
  iframe.setAttribute('src', el.dataset.kuula);

  element.appendChild(iframe);

};

export default ThreeSixtyModule;
