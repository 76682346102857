import {
  checkAndSubmitMktoForm,
  destyleMktoForm,
  loadMarketoFormsJs,
} from '../../../_scripts/utils/forms';

const InitMarketoForm = async (formElement, onLoad = null) => {
  return loadMarketoFormsJs().then(() => {
    const formId = formElement.getAttribute('data-marketo-form-id');
    const backupFormEl = document.getElementById('mktoForm_' + formId);

    const pushGaEvent = eventData => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(eventData);
    };

    const fixLabel = el => {
      if (el.value || el.options[el.selectedIndex].value !== '') {
        formElement.querySelectorAll('label[for=' + el.id + ']')[0].classList.add('mktoValid');
      } else {
        formElement.querySelectorAll('label[for=' + el.id + ']')[0].classList.remove('mktoValid');
      }
    };

    return new Promise((resolve, reject) => {
      MktoForms2.loadForm('//pages.skydio.com', '784-TUF-591', formId, form => {
        destyleMktoForm(form);
        const showSuccessMessage = formElement.getAttribute('data-show-success');
        AssignUTMs(formElement);
        if (formElement.elements['recentUTMUrl']) {
          formElement.elements['recentUTMUrl'].value = window.location.href.split('?')[0];
        }
        formElement.classList.add('init');
        for (let el in formElement.elements) {
          if (
            formElement.elements[el].tagName == 'INPUT' ||
            formElement.elements[el].tagName == 'TEXTAREA'
          ) {
            formElement.elements[el].addEventListener('change', e => {
              fixLabel(e.target);
            });
            if (formElement.elements[el].type == "checkbox") {
              const label = formElement.querySelector('#Lbl' + formElement.elements[el].id);
              if (label) {
                label.setAttribute('for', formElement.elements[el].id);
              }
            }
          } else if (formElement.elements[el].tagName == 'SELECT') {
            formElement
              .querySelectorAll('label[for=' + formElement.elements[el].id + ']')[0]
              .classList.add('skydio-select');
            formElement.elements[el].addEventListener('change', e => {
              fixLabel(e.target);
            });
          }
        }
        form.onValidate(valid => {
          if (!valid) {
            const fieldNames = Object.keys(form.getValues());
            fieldNames.forEach(id => {
              const el = document.querySelector('#mktoForm_' + formId + ' [name=' + id + ']');
              if (el) {
                const label = document.querySelector(
                  '#mktoForm_' + formId + ' label[for=' + id + ']',
                );
                if (label && el.value !== '') {
                  label.classList.add('mktoValid');
                }
              }
            });
          } else {
            try {
              const fieldNames = Object.keys(form.getValues());
              fieldNames.forEach(id => {
                const el = document.querySelector('#mktoForm_' + formId + ' [name=' + id + ']');
                if (el) {
                  const label = document.querySelector(
                    '#mktoForm_' + formId + ' label[for=' + id + ']',
                  );
                  if (label) {
                    label.classList.add('mktoValid');
                  }
                }
              });
            } catch (e) {
              console.log(e);
            }
            checkAndSubmitMktoForm(form);
          }
        });
        form.onSuccess((values, followUpUrl) => {
          if (showSuccessMessage) {
            if (formElement.dataset.gaFormType) {
              const eventData = {
                event: 'formSubmit',
                formType: formElement.dataset.gaFormType,
                formDescription: formElement.dataset.gaFormDesc,
                vertical: formElement.dataset.gaVertical,
              };

              pushGaEvent(eventData);
            }

            formElement.classList.add('marketo-form--success');
            Array.from(
              document.querySelectorAll(
                `[data-module-id="${formElement.dataset.marketoFormModule}"]`,
              ),
            ).forEach(el => {
              el.classList.add('market-form--module-success');
            });
            return false;
          }
          return false;
        });

        // calls a custom event to let the page know the form is loaded called 'marketoFormLoaded' and sends the form object as the event data
        const event = new CustomEvent('marketoFormLoaded', { detail: form });
        document.dispatchEvent(event);

        return resolve(form);
      });
    });
  });
};

function AssignUTMs(formEl) {
  var utmFieldMap = {
    utm_campaign: 'Recent_UTM_Campaign__c',
    utm_source: 'Recent_UTM_Source__c',
    utm_medium: 'Recent_UTM_Medium__c',
    utm_term: 'Recent_UTM_Term__c',
    utm_content: 'Recent_UTM_Content__c',
  };
  const cookieVal = getCookie('utmcookie');
  if (cookieVal) {
    var utmvals = JSON.parse(cookieVal);
    Object.keys(utmvals).map(key => {
      if (formEl.elements[utmFieldMap[utmvals[key].key]]) {
        formEl.elements[utmFieldMap[utmvals[key].key]].value = utmvals[key].value;
      }
    });
  }
}

function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export default InitMarketoForm;
