import { getBreakpoints } from "../utils/breakpoints";

const CollapseGroup = (element, alwaysCollapse = false) => {
  const button = element.querySelector("[data-collapsible-expand]");
  const content = element.querySelector("[data-collapsible-content]");

  let isMobile = null;
  let collapsed = true;

  if (element.hasAttribute("data-default-expanded")) {
    collapsed = false;
  }

  const updateCollapsed = () => {
    if (!isMobile && !alwaysCollapse) {
      content.style.maxHeight = "fit-content";
    } else {
      content.style.maxHeight = collapsed ? "0px" : `${content.scrollHeight}px`;
    }

    if (collapsed) {
      button.classList.remove("active");
    } else {
      button.classList.add("active");
    }
  };

  const setIsMobile = (newSetting) => {
    if (newSetting !== isMobile && !alwaysCollapse) {
      isMobile = newSetting;
      updateCollapsed();
    }
  };

  const onButtonClick = () => {
    collapsed = !collapsed;
    updateCollapsed();
  };

  const init = () => {
    button.addEventListener("click", onButtonClick);
    updateCollapsed();
  };

  init();

  return {
    setIsMobile,
  };
};

const CollapsibleContentGroups = (element) => {
  const groupElements = element.querySelectorAll("[data-collapsible-group]");
  const alwaysCollapse = element.dataset.collapsibleCollapseDesktop === "true";
  const groups = [];

  const resize = () => {
    const breakpoints = getBreakpoints();

    groups.forEach((g) => g.setIsMobile(breakpoints.md));
  };

  const init = () => {
    Array.from(groupElements).forEach((e) => {
      groups.push(CollapseGroup(e, alwaysCollapse));
    });

    window.addEventListener("resize", resize);
    resize();
  };

  init();
};

export default CollapsibleContentGroups;
