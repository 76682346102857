const CountdownTimer = element => {
  const layout = element.dataset.countdownTimerLayout;
  const startDate = layout === "circles" ? Date.parse(element.dataset.countdownTimerStartdatetime) : null;
  const endDate = Date.parse(element.dataset.countdownTimer);
  const timeDiff = startDate ? endDate - startDate : null;
  const daysDiff = timeDiff ? Math.floor(timeDiff / (1000 * 3600 * 24)) : null;

  const refs = {
    days: element.querySelector(`[data-value="days"]`).firstElementChild,
    hours: element.querySelector(`[data-value="hours"]`).firstElementChild,
    mins: element.querySelector(`[data-value="minutes"]`).firstElementChild,
    secs: element.querySelector(`[data-value="seconds"]`).firstElementChild,
  };

  const circleRefs =
    layout === 'circles'
      ? {
          days: element.querySelector(`[data-value="days"] svg circle`),
          hours: element.querySelector(`[data-value="hours"] svg circle`),
          mins: element.querySelector(`[data-value="minutes"] svg circle`),
          secs: element.querySelector(`[data-value="seconds"] svg circle`),
        }
      : {};

  const getTimeRemaining = () => {
    const total = endDate - new Date();
    if (total < 0) {
      return {
        total: new Date(),
        days: 0,
        hours: 0,
        mins: 0,
        secs: 0,
      };
    }
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const mins = Math.floor((total / 1000 / 60) % 60);
    const secs = Math.floor((total / 1000) % 60);
    return {
      total,
      days,
      hours,
      mins,
      secs,
    };
  };

  const updateTimer = () => {
    const tr = getTimeRemaining();
    refs.secs.textContent = tr.secs.toString().padStart(2, '0');
    refs.mins.textContent = tr.mins.toString().padStart(2, '0');
    refs.hours.textContent = tr.hours.toString().padStart(2, '0');
    refs.days.textContent = tr.days.toString().padStart(2, '0');

    if (layout === 'circles' && startDate) {
      circleRefs.days.style.strokeDashoffset = `${377 - ((tr.days / daysDiff) * 377)}`;
      circleRefs.hours.style.strokeDashoffset = `${377 - ((tr.hours / 24) * 377)}`;
      circleRefs.mins.style.strokeDashoffset = `${377 - ((tr.mins / 60) * 377)}`;
      circleRefs.secs.style.strokeDashoffset = `${377 - ((tr.secs / 60) * 377)}`;
    }
  };

  const init = () => {
    setInterval(() => {
      updateTimer();
    }, 500);
  };

  init();

  return {};
};

export default CountdownTimer;
