import Swiper, { A11y, Navigation, Pagination } from "swiper";

import { getBreakpoints } from "../../../_scripts/utils/breakpoints";

const ResponsiveSlider = (element, callbacks = {}) => {
  const slides = element.innerHTML;
  const options = {
    grabCursor: element.dataset.grabCursor === "true",
    slidesPerView: element.dataset.slidesPerView ?? 1,
    centeredSlides: element.dataset.centered !== "false",
    centeredSlidesBounds: element.dataset.centeredSlidesBounds === "true",
    spaceBetween: isNaN(parseInt(element.dataset.spaceBetween))
      ? 0
      : parseInt(element.dataset.spaceBetween),
    direction: element.dataset.direction ?? "horizontal",
    autoHeight: element.dataset.autoHeight === "true",
    navigation:  element.dataset.navigation === "true",
    pagination: {
      type: element.dataset.paginationType ?? "bullets",
      clickable: element.dataset.clickable !== "false",
      ...(element.dataset.paginationElement ? {el: element.dataset.paginationElement} : {})
    },
  };

  const sliderBreakpoint = element.dataset.breakpoint ?? "md";
  delete options["breakpoint"];

  let swiper = null;

  const slideChange = () => {};

  const initSwiper = (swiperElement) => {
    const combinedOptions = {
      ...options,
      on: {
        slideChange: slideChange,
      },
      modules: [Navigation, Pagination, A11y],
    };
    if (combinedOptions.pagination) {
      combinedOptions.pagination.el = element.querySelector(".swiper-pagination");
    }
    swiper = new Swiper(swiperElement, combinedOptions);
    if (callbacks.onInitializationComplete) {
      callbacks.onInitializationComplete();
    }
  };

  const destroySwiper = () => {
    if (swiper) {
      swiper.destroy();
      swiper = null;
      if (callbacks.onDestroyComplete) {
        callbacks.onDestroyComplete();
      }
    }
  };

  const createSwiperContainer = () => {
    const currentSlides = Array.from(element.querySelectorAll(":scope > :not([class^='swiper-'])"));

    element.insertAdjacentHTML(
      "afterbegin",
      `<div class="swiper">
        <div class="swiper-wrapper">${currentSlides
          .map((slide) => `<div class="swiper-slide">${slide.outerHTML}</div>`)
          .join("")}
        </div>
        <div class="swiper-pagination"></div>
        ${options.navigation ? `
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        ` : ""}
      </div>`
    );
    currentSlides.forEach((slide) => element.removeChild(slide));
  };

  const removeSwiperContainer = () => {
    element
      .querySelectorAll(":scope > [class^='swiper']")
      .forEach((child) => element.removeChild(child));
    element.insertAdjacentHTML("afterbegin", slides);
  };

  const resize = () => {
    const breakpoints = getBreakpoints();

    if (breakpoints[sliderBreakpoint] && !swiper) {
      element.classList.remove("responsive-slider--md-grid");
      createSwiperContainer();
      initSwiper(element.querySelector(".swiper"));
    } else if (!breakpoints[sliderBreakpoint] && swiper) {
      element.classList.add("responsive-slider--md-grid");
      destroySwiper();
      removeSwiperContainer();
    }
  };

  const addListeners = () => {
    window.addEventListener("resize", () => resize());
  };

  const init = () => {
    addListeners();
    resize();
  };

  init();

  return {
    element,
    swiper,
  };
};

export default ResponsiveSlider;
