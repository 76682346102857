import Modal from "../modal/script";
import VideoPlayer from "../../macros/video/script";
import { htmlToElement as el } from "../../../_scripts/utils/dom";

const VideoModule = (element) => {
  const playButton = element.querySelector("[data-video-module-play]");
  const videoContainer = element.querySelector("[data-video-module-full]");
  const clipContainer = element.querySelector("[data-video-module-clip]");
  const useModal = element.dataset.videoModuleModal === "true";
  let modalContent = null;
  let modal = null;
  let video = null;
  let videoPreview = null;

  const pauseVideo = () => {
    //element.classList.remove("video-module--playing");
    //video.currentTime(0);
  };

  const closeModal = () => {
    video.video.pause();
    video.video.currentTime(0);
    if (videoPreview) {
      videoPreview.video.play();
    }
  };

  const playVideo = () => {
    video = VideoPlayer(modalContent, { autoplay: true });

    if (useModal) {
      if (modal) {
        modal.show();
        video.video.play();
      } else {
        modal = Modal(modalContent, "video", { show: true, onClose: () => closeModal() });
      }
    } else {
      element.classList.add("video-module--playing");
      videoContainer.append(modalContent);
    }
    if (videoPreview) {
      videoPreview.video.pause();
    }
  };

  const onActivateVideo = (v) => {
    video = v;
    video.on("pause", () => pauseVideo());
  };

  const init = () => {
    modalContent = videoContainer.firstElementChild;
    videoContainer.removeChild(modalContent);
    videoPreview = VideoPlayer(clipContainer.querySelector(".sanity-video"));
    element.addEventListener("click", () => playVideo());
    element.addEventListener("keydown", (e) => {
      if (e.keyCode === 13) {
        playVideo();
      }
    });
  };

  init();

  return {};
};

export default VideoModule;
