import Cookies from "js-cookie";
const cookieName = "skydio-banner";

export class AnnouncementBanner {
  constructor(element, header) {
    this.container = element;
    this.header = header;
    this.bannerCleared = Cookies.get(cookieName) === "true";
    this.addEventListeners();
    this.calculateHeaderPosition();
  }

  set bannerCleared(value) {
    if (value) {
      this.container.classList.add("hidden");
    } else {
      this.container.classList.remove("hidden");
    }
    Cookies.set(cookieName, value === true ? "true" : null);

    this._bannerCleared = value;
  }

  get bannerCleared() {
    return this._bannerCleared;
  }

  addEventListeners() {
    if (this.bannerCleared) return;

    window.addEventListener("scroll", () => this.calculateHeaderPosition());
    document.querySelector(".announcement-banner__dismiss")?.addEventListener("click", () => {
      this.bannerCleared = true;
      this.calculateHeaderPosition();
    });
    window.addEventListener("resize", () => this.calculateHeaderPosition());
  }

  get height() {
    return this.container.offsetHeight;
  }

  set headerPos(value) {
    if (this._headerPos !== value) {
      this._headerPos = value;
      const headerEvent = new CustomEvent("headerOffsetChange", { detail: { offset: this._headerPos, offsetName: "announcementBanner" } });
      document.dispatchEvent(headerEvent);
    }
  }

  get headerPos() {
    return this._headerPos;
  }

  calculateHeaderPosition() {
    const headerPos = this.height - window.scrollY;
    const headerStyles = window.getComputedStyle(this.header);
    const headerMargin = parseInt(headerStyles.marginBottom);

    if (headerPos >= 0) { 
      this.header.style.marginTop = headerMargin + this.height + "px"; 
    } else if (this.headerPos !== 0) {
      this.header.style.marginTop = headerMargin + "px"; 
    }
  }
}