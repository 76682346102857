export class AnimateText {
  constructor(element) {
    this.container = element;
    this.items = Array.from(this.container.querySelectorAll('.animate-text__item'));
    this.current = 0;
    this.initDelay = parseFloat(this.container.dataset.animateTextDelay) * 1000 ?? 0;

    this.init();
  }

  init() {
    if (this.initDelay > 0) {
      setInterval(() => {
        this.next();
      }, this.initDelay);
    }
  }

  get current() {
    return this._current;
  }

  set current(value) {
    if (value === this._current) {
      return;
    }
    this.resetNotActive();
    this.hideCurrent();
    this._current = value;
    this.items[this.current].classList.add('animate-text__item--active');
    this.items[this.current].classList.remove('animate-text__item--initial');
  }

  resetNotActive() {
    this.items
      .filter(el => !el.classList.contains('animate-text__item--active'))
      .forEach(item => {
        item.classList.remove('animate-text__item--end');
        item.classList.add('animate-text__item--initial');
      });
  }

  hideCurrent() {
    if (!this.items[this.current]) {
      return;
    }

    this.items[this.current].classList.add('animate-text__item--end');
    this.items[this.current].classList.remove('animate-text__item--active');
  }

  next() {
    this.current = this.items.length - 1 > this.current ? this.current + 1 : 0;
  }
}
export default element => new AnimateText(element);
