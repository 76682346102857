class DropContentModule {
  constructor(element) {
    this.container = element;
    this.content = element.querySelector('[data-drop-content-module-content]');
    this.moduleId = element.dataset.dropContentModule;
    this.innerContent = element.querySelector('[data-drop-content-module-inner-content]');
    this.closeButton = element.nextElementSibling.querySelector('[data-drop-content-module-close]');
    this.callerModuleId = null;
    this.addEventListeners();
    this.setAnimationDuration();
    this.title = element.dataset.dropContentName;
  }

  addEventListeners() {
    this.content.addEventListener('click', () =>
      setTimeout(this.setAnimationDuration.bind(this), 1000),
    );
    window.addEventListener('dropInContentToggle', this.handleToggle.bind(this));
    window.addEventListener('resize', this.handleResize.bind(this));
    this.closeButton.addEventListener('click', this.toggle.bind(this));
  }

  handleToggle(event) {
    if (event.detail.moduleId === this.moduleId) {
      this.toggle();
      this.callerModuleId = event.detail.containerModuleId;
    }
  }

  setAnimationDuration() {
    this.container.style.setProperty(
      '--drop-in-content-module-anim-duration',
      `${(this.height() / (window.innerHeight / 2)) * 0.2 + 0.1}s`,
    );
  }

  handleResize() {
    this.setAnimationDuration();
  }

  height() {
    const containerTopBottomPadding =
      parseInt(window.getComputedStyle(this.container).paddingBottom) +
      parseInt(window.getComputedStyle(this.container).paddingTop);
    return this.innerContent.scrollHeight + containerTopBottomPadding;
  }

  scrollIntoView(element) {
    const containerTop = element.getBoundingClientRect().top;
    const scrollPosition = window.scrollY;
    const headerOffsetHeight = parseInt(document.body.style.getPropertyValue('--header-height'));

    window.scrollTo({
      top: scrollPosition + containerTop - headerOffsetHeight,
      left: 0,
      behavior: 'smooth',
    });
  }

  sendAnalytics() {
    if (this.title && this.title.length > 0) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'drawer',
        action: this.isOpen ? 'open' : 'close',
        title: this.title,
      });
    }
  }

  // When toggled we get the height of the content and set it as a CSS variable.
  // This allows us to animate the height of the content without knowing the height.
  open() {
    this.container.parentElement.classList.add('drop-in-content--open');
    this.scrollIntoView(this.container);

    window.dispatchEvent(
      new CustomEvent('dropInContentOpen', { detail: { moduleId: this.moduleId } }),
    );
  }

  close() {
    this.container.parentElement.classList.remove('drop-in-content--open');

    if (this.callerModuleId) {
      const element = document.querySelector(`[data-module-id="${this.callerModuleId}"]`);
      if (element) {
        this.scrollIntoView(element);
      }
    }

    window.dispatchEvent(
      new CustomEvent('dropInContentClose', { detail: { moduleId: this.moduleId } }),
    );
  }

  get isOpen() {
    return this.container.parentElement.classList.contains('drop-in-content--open');
  }

  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }

    this.sendAnalytics();
  }
}

export default element => new DropContentModule(element);
