export const htmlToElement = (html) => {
  const templateElement = document.createElement("template");
  templateElement.innerHTML = html.trim();
  return templateElement.content.childNodes[0];
};

export const htmlToElements = (html) => {
  const templateElement = document.createElement("template");
  templateElement.innerHTML = html;
  return templateElement.content.childNodes;
};

export const addScript = (src, callback) => {
  let s, r, t;
  r = false;
  s = document.createElement("script");
  s.type = "text/javascript";
  s.src = src;
  s.onload = s.onreadystatechange = function () {
    if (!r && (!this.readyState || this.readyState == "complete")) {
      r = true;
      callback();
    }
  };
  t = document.getElementsByTagName("script")[0];
  t.parentNode.insertBefore(s, t);
};
