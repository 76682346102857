import { PopupWithModules } from '../../modules/popupModule/popupWithModules';

class AppCard {
  constructor(element) {
    this.element = element;
    this.contentId = this.element.dataset.appCardAppId;
    this.popup = null;
    this.init();
  }

  init() {
    this.addEventListeners();
  }

  addEventListeners() {
    this.element.addEventListener('click', this.handleClick.bind(this));
  }

  handleClick(event) {
    if (!this.popup) {
      this.popup = new PopupWithModules(this.element.dataset.popupId, {
        v2Style: true,
        animation: 'fade',
        overlayOpacity: 0.8,
        overlayBlur: 10,
        classes: 'app-card__popup',
        loadImmediately: true,
        closeOnOverlayClick: true,
        contentUrl: `/applications/gallery/${this.contentId}.html`
      });
    } else {
      this.popup.show();
    }
  }
}

export default element => new AppCard(element);
