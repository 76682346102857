import { htmlToElements as el } from '../../../_scripts/utils/dom';
import { isIterable } from '../../../_scripts/utils/helpers';

export class PopupBase {
  constructor(id, options, element) {
    this.options = {
      smallPopup: options.layout === 'small',
      v2Style: true, // options.v2Style == null || options.v2Style == true,
      track: options.track || options.track === 'true',
      trackPopupCloseOnSessionBasis: options.trackPopupCloseOnSessionBasis || options.trackPopupCloseOnSessionBasis === 'true',
      animation: options.animation,
      overlayOpacity: options.overlayOpacity ?? 0,
      overlayBlur: options.overlayBlur ?? 0,
      layout: options.layout,
      maxWidth: options.maxWidth,
      containerSize: options.containerSize,
      classes: options.classes,
      style: options.style,
      styles: options.styles,
      loadImmediately: options.loadImmediately === true,
      contentUrl: options.contentUrl,
      onLoad: options.onLoad ?? (() => true),
      onClose: options.onClose ?? (() => {}),
    };

    this.element = element;
    this.grouping = options.grouping;
    this.id = id;

    this.initialize();
  }

  get close() {
    return this.popup?.querySelector('[data-popup-close]');
  }

  get content() {
    return this._content;
  }

  set content(html) {
    this._content = typeof html == 'string' ? el(html) : html;
  }

  initialize() {
    this.createPopupContainer();

    if (this.options.track && localStorage.getItem(`popup-${this.grouping}`) === 'true') {
      this.doNotShow = true;
      return;
    }

    
    if (this.options.trackPopupCloseOnSessionBasis && sessionStorage.getItem(`popup-${this.grouping}`) === 'true') {
      this.doNotShow = true;
      return;
    }

    if (this.options.v2Style) {
      this.popup.classList.add('popup--v2', 'v2');
    }

    if (this.options.smallPopup) {
      this.popup.classList.add('popup--small');
    }

    if (this.options.style && this.options.style.length > 0) {
      this.popup.classList.add(`popup--${this.options.style}`);
    }

    this.getContent()
      .then(c => {
        this.content = c;
        this.options.onLoad(this);
      })
      .then(() => {
        if (this.options.loadImmediately) {
          this.show();
        }
      });
  }

  createPopupContainer() {
    this.popup = this.createElements();
    this.addListeners();
  }

  async getContent() {
    if (this.element) {
      return this.element.outerHTML;
    } else {
      this.loadingResources = true;

      return fetch(this.options.contentUrl ?? `/popups/${this.id}/index.html`).then((response) => {
        this.loadingResources = false;
        return response.text();
      });
    }
  }

  async show() {
    if (this.doNotShow) return;

    await this.loadPopupAndAddToDOM().then(() => {
      if (!this.options.smallPopup) {
        document.body.classList.add('popup__body');
      }

      this.popup.classList.add('popup--visible');

      setTimeout(() => this.popup.classList.add('popup--anim'), 200);
    });
  }

  async loadPopupAndAddToDOM() {
    document.body.append(this.popup);

    if (!this.contentAddedToPopup && isIterable(this.content)) {
      this.popup.querySelector('.popup__inner').append(...this.content);
      this.contentAddedToPopup = true;
    }
  }

  createElements() {
    const classes = [];

    if (this.options.animation) {
      classes.push(`popup--anim-${this.options.animation}`);
    }

    if (this.options.classes) {
      classes.push(this.options.classes);
    }

    const styles = [];

    if (!this.options.smallPopup) {
      styles.push(`--popup-overlay-opacity: ${this.options.overlayOpacity}`);
      styles.push(`--popup-overlay-blur: ${this.options.overlayBlur}px`);
    }

    if (this.options.smallPopup && this.options.maxWidth) {
      styles.push(`--popup-max-width: ${this.options.maxWidth}`);
    }

    if (this.options.styles) {
      styles.push(this.options.styles);
    }

    const innerContainerClasses = [];

    if (this.options.containerSize && !this.options.smallPopup) {
      innerContainerClasses.push(`container--${this.options.containerSize}`);
    }

    let closeButton = `<svg width="11" height="11" fill="currentColor" viewBox="0 0 11 11"><path d="M7.43359 5.375L10.3633 2.44531C10.7441 2.09375 10.7441 1.50781 10.3633 1.15625L9.71875 0.511719C9.36719 0.130859 8.78125 0.130859 8.42969 0.511719L5.5 3.44141L2.54102 0.511719C2.18945 0.130859 1.60352 0.130859 1.25195 0.511719L0.607422 1.15625C0.226562 1.50781 0.226562 2.09375 0.607422 2.44531L3.53711 5.375L0.607422 8.33398C0.226562 8.68555 0.226562 9.27148 0.607422 9.62305L1.25195 10.2676C1.60352 10.6484 2.18945 10.6484 2.54102 10.2676L5.5 7.33789L8.42969 10.2676C8.78125 10.6484 9.36719 10.6484 9.71875 10.2676L10.3633 9.62305C10.7441 9.27148 10.7441 8.68555 10.3633 8.33398L7.43359 5.375Z" fill="white" /></svg>`;

    if (this.options.v2Style) {
      closeButton = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31" width="31" height="31" fill="none"><path stroke="#000" d="m0 1 30 29M29 1 1 30"/></svg>`;
    }


    return el(`<div class="popup">
      <div class="popup__overlay ${classes.join(' ')}" style="${styles.join('; ')}">
        <div class="popup__outer container ${innerContainerClasses.join(' ')}">
          <div class="popup__wrap">
            <button class="popup__close" data-popup-close>${closeButton}</button>
            <div class="popup__inner">
            </div>
          </div>
        </div>
      </div>
    </div>`)[0];
  }

  hide() {
    this.popup.classList.add('popup--anim-reverse');
    this.popup.classList.remove('popup--anim');

    setTimeout(() => {
      this.popup.classList.remove('popup--visible');
      this.popup.classList.remove('popup--anim-reverse');
      document.body.classList.remove('popup__body');
      document.body.removeChild(this.popup);
      this.options.onClose();
    }, 300);

    if (!this.options.trackPopupCloseOnSessionBasis && this.options.track && this.grouping) {
      localStorage.setItem(`popup-${this.grouping}`, 'true');
    }

    if (this.options.trackPopupCloseOnSessionBasis && this.grouping) {
      sessionStorage.setItem(`popup-${this.grouping}`, 'true');
    }
  }

  addListeners() {
    this.close.addEventListener('click', this.hide.bind(this));
  }
}
