class AnimatedHeadline {
  constructor(element) {
    this.animatedElement = element;
    this.textContent = this.animatedElement.textContent;
    this.originalTextContent = this.textContent;
    this.range = document.createRange();
    this.lines = [];
    this.lineCharacters = [];
    this.animationDelay = parseInt(element.dataset.animationDelay);
    this.animationDuration = parseInt(element.dataset.animationDuration);
    this.addListeners();
    this.init();
  }

  addListeners() {
    window.addEventListener('resize', () => {
      if (this.isVisible(this.animatedElement)) {
        this.restoreOriginalText();
      }
    });
  }

  isVisible(element) {
    const { top, bottom } = element.getBoundingClientRect();
    const vHeight = window.innerHeight || document.documentElement.clientHeight;
    return (top > 0 || bottom > 0) && top < vHeight;
  }

  restoreOriginalText() {
    this.animatedElement.textContent = this.originalTextContent;
  }

  init() {
    // remove break tags? - make note to set a width in css for the element?
    this.animatedElement.innerHTML = this.animatedElement.textContent.replace(/<br>/g, '');

    for (let i = 0; i < this.textContent.length; i++) {
      this.range.setStart(this.animatedElement.firstChild, 0);
      this.range.setEnd(this.animatedElement.firstChild, i + 1);

      let lineIndex = this.range.getClientRects().length - 1;

      if (!this.lines[lineIndex]) {
        this.lines.push((this.lineCharacters = []));
      }

      this.lineCharacters.push(this.textContent.charAt(i));
    }

    this.lines = this.lines.map(characters => {
      return characters.join('');
    });

    this.animatedElement.textContent = '';

    let staggeredAnimationDelay = this.animationDelay;

    this.lines.forEach(line => {
      let outerSpanElement = document.createElement('span');
      let innerSpanElement = document.createElement('span');
      outerSpanElement.classList.add('outer');
      innerSpanElement.classList.add('inner');
      innerSpanElement.style.transitionDuration = `${this.animationDuration}ms`;
      innerSpanElement.textContent = line;
      outerSpanElement.append(innerSpanElement);
      this.animatedElement.append(outerSpanElement);

      setTimeout(() => {
        innerSpanElement.classList.add('initialize-animation');
      }, staggeredAnimationDelay);

      staggeredAnimationDelay = staggeredAnimationDelay + this.animationDelay;
    });
    this.animatedElement.style.opacity = 1;
  }
}

const AnimatedHeadlineModule = element => new AnimatedHeadline(element);

export default AnimatedHeadlineModule;
