import { LanguageSwitcherBase } from '../languageSwitcher';

export class LanguageSwitcher {
  constructor(buttonElement, switcherElement) {
    this.buttonElement = buttonElement;
    this.switcherElement = switcherElement;
    this.timeout = null;
    this.init();
  }

  init() {
    this.buttonElement.addEventListener('click', () => this.toggle());
    this.buttonElement.addEventListener('keydown', e => {
      if (e.keyCode === 13) {
        this.toggle();
      }
    });
    this.buttonElement.addEventListener('mouseenter', () => this.show());
    this.buttonElement.addEventListener('mouseleave', () => this.hide());
    this.switcherElement.addEventListener('mouseenter', () => this.show());
    this.switcherElement.addEventListener('mouseleave', () => this.hide());
    this.lang = new LanguageSwitcherBase(this.switcherElement);
  }

  toggle() {
    if (this.switcherElement.classList.contains('locale-switcher--active')) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.switcherElement.classList.add('locale-switcher--active');
  }

  hide() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.switcherElement.classList.remove('locale-switcher--active');
  }
}