import { SimplifiedEventListener } from '../../../../../../_scripts/utils/simplifiedEventListener';

export class SubNavMenuItem extends SimplifiedEventListener {
  constructor(element, options) {
    super(element);
    this.container = element;
    this.options = options;
    this.init();
  }

  init() {
    if (this.container.querySelector('.sub-nav__link-inner')) {
      return;
    }

    this.container.innerHTML = `<span class="sub-nav__link-inner"><span class="sub-nav__link-text">${this.container.innerHTML}</span></span>`;
    this.hide();
  }

  get animating() {
    return this.container.classList.contains('sub-nav__link--animate');
  }

  set animating(value) {
    if (value) {
      this.container.classList.add('sub-nav__link--animate');
    } else {
      this.container.classList.remove('sub-nav__link--animate');
    }
  }

  show() {
    this.container.classList.remove('sub-nav__link--initial');
  }

  hide() {
    this.container.classList.add('sub-nav__link--initial');
    this.animating = false;
  }
}