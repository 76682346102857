export const CategoryNav = (element) => {
  const onChange = (event) => {
    window.location.href = event.target.value;
  };

  const init = () => {
    element.addEventListener("change", onChange);
  };

  const destroy = () => {
    element.removeEventListener("change", onChange);
  };

  init();

  return {
    destroy,
  };
};

export const SocialShareCopyLink = (element) => {
  const onClick = () => {
    navigator.clipboard.writeText(element.dataset.socialCopyUrl);

    element.classList.add("copylink--success");

    setTimeout(() => {
      element.classList.remove("copylink--success");
    }, 2000)
  };

  const init = () => {
     element.addEventListener("click", onClick);
  };

  const destroy = () => {
    element.removeEventListener("click", onClick)
  }

  init();

  return {
    destroy
  }
};
