export const createImageFromJson = (json, options = {}, classes = "") => {
  return `<div class="skydio-image-wrapper"><img
        alt="${json.imgAlt ? json.imgAlt : ""}"
        height="${json.height}"
        width="${json.width}"
        class="${classes}"
        src="${json?.images?.fallback?.src}"
        srcSet="${json?.images?.fallback?.srcSet}"
        sizes="${json?.images?.fallback?.sizes}"
        style="${options.imgFit ? `object-fit: ${options.imgFit};` : ""} ${
    options.imgStyles ? options.imgStyles: ""
  }"
        loading="${options.loading ? options.loading : "lazy"}"
      /></div> `;
};
