class Filterable {
  constructor(element, container) {
    this.element = element;
    this.container = container;
  }

  get hidden() {
    return this.element.classList.contains('filtered');
  }

  set hidden(value) {
    if (value) {
      this.element.classList.add('filtered');
    } else {
      this.element.classList.remove('filtered');
    }
  }
}

class ParentDepartment extends Filterable {
  constructor(element, container) {
    super(element, container);

    this.id = element.dataset.parentDepartmentId;
    this.departments = Array.from(
      this.container.querySelectorAll(
        `.job-listings__department[data-parent-department-id="${element.dataset.parentDepartmentId}"]`,
      ),
    ).map(el => new Department(el, this.container));
  }

  get numberOfJobs() {
    return this.departments.reduce((total, department) => total + department.numberOfJobs, 0);
  }

  filter(filters) {
    this.departments.forEach(department => department.filter(filters));
    this.hidden = this.departments.every(department => department.hidden);
  }
}

class Department extends Filterable {
  constructor(element, container) {
    super(element, container);

    this.id = element.dataset.departmentId;
    this.parentId = element.dataset.parentDepartmentId;
    this.jobListings = Array.from(
      this.container.querySelectorAll(
        `.job-listings__job[data-department-id="${element.dataset.departmentId}"]`,
      ),
    ).map(el => new JobListing(el, this.container));
  }

  get numberOfJobs() {
    return this.jobListings.filter(job => !job.hidden).length;
  }

  get hidden() {
    return super.hidden;
  }
  set hidden(value) {
    if (value) {
      this.jobListings.forEach(job => (job.hidden = true));
    }

    super.hidden = value;
  }

  filter(filters) {
    if (
      filters.departmentId !== '' &&
      filters.departmentId !== this.id &&
      filters.departmentId !== this.parentId
    ) {
      this.hidden = true;
      return;
    }

    const reducedFilters = { ...filters };
    delete reducedFilters.departmentId;

    this.jobListings.forEach(job => {
      job.filter(reducedFilters);
    });
    this.hidden = this.jobListings.every(job => job.hidden);
  }
}

class JobListing extends Filterable {
  constructor(element, container) {
    super(element, container);
  }

  filter(filters) {
    let filterCount = 0;
    const activeFilters = Object.keys(filters).filter(key => filters[key] !== '');
    activeFilters.forEach(key => {
      filterCount += this.element.dataset[key] === filters[key] ? 1 : 0;
    });
    this.hidden = filterCount !== activeFilters.length;
  }
}

class JobListings {
  constructor(element) {
    this.container = element;
    this.departmentSelect = this.container.querySelector('#jobListings_department');
    this.locationSelect = this.container.querySelector('#jobListings_location');
    this.filters = {
      departmentId: this.departmentSelect.value,
      locationId: this.locationSelect.value,
    };

    this.total = 0;

    this.parentDepartments = Array.from(
      this.container.querySelectorAll('.job-listings__parent-department'),
    ).map(item => new ParentDepartment(item, this.container));

    this.noResultsElement = this.container.querySelector('.job-listings__no-results');

    this.addEventListeners();
    this.onFilterChange();
  }

  addEventListeners() {
    this.departmentSelect.addEventListener('change', event => {
      this.filters.departmentId = event.target.value;
      this.onFilterChange();
    });

    this.locationSelect.addEventListener('change', event => {
      this.filters.locationId = event.target.value;
      this.onFilterChange();
    });
  }

  onFilterChange() {
    this.parentDepartments.forEach(parentDepartment => parentDepartment.filter(this.filters));

    this.total = this.parentDepartments.reduce(
      (total, parentDepartment) => total + parentDepartment.numberOfJobs,
      0,
    );

    this.container.querySelector('.job-listings__total span').innerHTML = this.total;
    this.noResultsElement.classList.toggle('hidden', this.total !== 0);
  }
}

const JobListingsModule = element => {
  return {
    JobListings: new JobListings(element),
  };
};

export default JobListingsModule;
