import { createImageFromJson } from "../../../_scripts/utils/media";
import {
  htmlToElement as el,
  htmlToElements as els,
} from "../../../_scripts/utils/dom";
import Modal from "../modal/script";
import Swiper, { A11y, EffectFade, Keyboard, Navigation, Pagination } from "swiper";
import {getBreakpoints} from "../../../_scripts/utils/breakpoints";
import ResponsiveSlider from "../../macros/responsiveSlider/script";

const ImageGallery = (element) => {
  const sliderOptions = {
    autoHeight: true,
    effect: "fade",
    fadeEffect: { crossFade: true },
    grabCursor: true,
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 12,
    pagination: {
      clickable: true,
      el: ".swiper-pagination",
    },
    keyboard: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  const imageData = JSON.parse(unescape(element.dataset.igImages));
  const images = els(
    imageData
      .map((image) => `<div class="swiper-slide">${createImageFromJson(image)}</div>`)
      .join("")
  );

  let modalContent = null;
  let modal = null;
  let modalSlider = null;
  let responsiveSlider = null;
  let isMobile = false;


  const createModalContent = () => {
    modalContent = el(
      `<div class="swiper">
        <div class="swiper-wrapper"></div>
        <div class="swiper-pagination"></div>
        <div class="swiper-navigation">
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>`
    );
  };

  const loadModal = (initialImageIndex) => {
    createModalContent();
    modal = Modal(modalContent, "image-lightbox", { show: true });
    modalContent.querySelector(".swiper-wrapper").append(...images);

    modalSlider = new Swiper(modalContent, {
      initialSlide: initialImageIndex,
      ...sliderOptions,
      modules: [A11y, EffectFade, Navigation, Pagination, Keyboard],
    });
  };

  const displayLargeImage = (imageIndex) => {
    if (modal) {
      modalSlider.slideTo(imageIndex, 0, false);
      modal.show();
    } else {
      loadModal(imageIndex);
    }
  };

  const bindButtonEvents = () => {
    Array.from(element.querySelectorAll("[data-ig-index]")).forEach((b) => {
      const id = parseInt(b.dataset.igIndex);
      b.addEventListener("click", () => displayLargeImage(id));
    });
  };


  const resize = () => {
    // TODO: Create a breakpoint event handler
    const breakpoints = getBreakpoints();

    isMobile = !breakpoints.md
  }

  const init = () => {
    responsiveSlider = ResponsiveSlider(element.querySelector(".responsive-slider"), {
      onInitializationComplete: () => {
        bindButtonEvents();
      },
      onDestroyComplete: () => {
        bindButtonEvents();
      }
    });
    bindButtonEvents();
    window.addEventListener("resize", resize);
  };

  const destroy = () => {
    if (modalSlider) {
      modalSlider.destroy();
      modalSlider = null;
    }
    if (modal) {
      modal.destroy();
      modal = null;
    }
    window.removeEventListener("resize", resize);
  };

  init();

  return {
    destroy,
  };
};

export default ImageGallery;
