import InitMarketoForm from '../../_includes/modules/marketoFormModule/script';
import { htmlToElement as el } from '../utils/dom';

class ContactForm {
  constructor(container, formShortCode, locale, options) {
    this.container = container;
    this.locale = locale;
    this.formShortCode = formShortCode;
    this.onLoad = options?.onLoad;
    this.onSuccess = options?.onSuccess;
    this.form = null;
    this.init();
  }

  init() {
    this.loadForm();
  }

  loadForm() {
    fetch(`${FULL_URL}${this.locale && this.locale !== "en-us" ? `/${this.locale.toLowerCase()}` : ""}/forms/${this.formShortCode}`).then(response => {
      if (response.ok) {
        response.text().then(async html => {
          this.container.innerHTML = html;
          InitMarketoForm(this.container.querySelector('form')).then(form => {
            this.form = form;

            if (this.onLoad) {
              this.onLoad();
            }

            this.loadComplete();
          });
        });
      }
    });
  }

  loadComplete() {
    this.form.onSuccess((values, followUpUrl) => {
      this.container.querySelector('.loadable-form__title').style.display = 'none';
      if (this.onSuccess) {
        this.onSuccess();
      }
    });
  }

  destroy() {
    this.container.innerHTML = '';
    this.container = null;
  }
}

class ContactForms {
  constructor() {
    this.container = document.querySelector("[data-contact-form-options]") ?? this.createFormsContainer();
    this.locale = this.container.dataset.contactFormLocale
    this.formContainer = this.container.querySelector('.contact-forms__form');
    this.backContainer = this.container.querySelector('.contact-forms__back');
    this.backButton = this.backContainer.querySelector('[data-contact-back]');
    this.options = this.container.querySelector('.contact-forms__options');
    this.numOptions = this.options.querySelectorAll('[data-contact-form]').length;
    this.currentForm = null;
    this.init();
  }

  init() {
    this.addEventListeners();

    if (this.numOptions === 1) {
      this.backButton.style.display = 'none';
      return;
    }

    document.body.appendChild(this.container);
  }

  addEventListeners() {
    this.container.querySelector('[data-contact-close]').addEventListener('click', () => {
      this.hide();
    });
    this.container.addEventListener(
      'touchmove',
      function (e) {
        if (e.target.closest('.contact-forms')) {
          return;
        }
        e.preventDefault();
      },
      false,
    );
    this.backButton.addEventListener('click', () => {
      this.showOptions();
    });
    this.container.querySelectorAll('[data-contact-form]').forEach(button => {
      button.addEventListener('click', () => {
        this.showContactForm(button.dataset.contactForm);
      });
    });
  }

  createFormsContainer() {
    return el(`<div class="contact-forms v2">
        <div class="contact-forms__container">
           <div class="contact-forms__close-container">
             <button class="contact-forms__close" data-contact-close>
               <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24"><g clip-path="url(#plusicon)"><path fill="#fff" fill-rule="evenodd" d="M13.3 9.9V.7h-2v9.2h2Zm-2 3.1H1v-2h10.3a2 2 0 0 1 2 2v10.3h-2V13Zm12.3-2h-9.2v2h9.2v-2Z" clip-rule="evenodd"></path></g><defs><clipPath id="plusicon"><path fill="#fff" d="M.3 0h24v24H.3z"></path></clipPath></defs></svg>
             </button>
           </div>
            <div class="contact-forms__body">
              <div class="contact-forms__options">
               <div class="contact-forms__header">
                  <h2 class="contact-forms__title">What team would you like to contact today?</h2>
               </div>
                <button class="button contact-forms__option" data-contact-form="sales" data-contact-form-id="1363">Sales</button>
                <a href="https://support.skydio.com" class="button contact-forms__option">Support</a>
                <button class="button contact-forms__option" data-contact-form="partner" data-contact-form-id="1364">Tech Partnership</button>
                <button class="button contact-forms__option" data-contact-form="reseller" data-contact-form-id="1369">Reseller</button>
                <button class="button contact-forms__option" data-contact-form="press" data-contact-form-id="1368">Press</button>
              </div>
              <div class="contact-forms__form">
              </div>
              <div class="contact-forms__back skydio-form">
                 <button class="button button--transparent contact-forms__back-button" data-contact-back>Back</button>
              </div>
            </div>
        </div>
      </div>`);
  }

  showContactForm(formShortCode) {
    this.formContainer.innerHTML = '';

    if (!this.options.querySelector(`[data-contact-form="${formShortCode}"]`)) {
      if (this.numOptions === 1) {
        formShortCode = this.options.querySelector('[data-contact-form]').dataset.contactForm;
      } else {
        this.showOptions();
        return;
      }
    }

    this.currentForm = new ContactForm(this.formContainer, formShortCode, this.locale, {
      onLoad: this.formLoaded.bind(this),
      onSuccess: this.formPosted.bind(this),
    });

    this.showForm();
  }

  formLoaded() {
    this.backContainer.classList.add('contact-forms__back--visible');
  }

  formPosted() {
    this.backContainer.classList.remove('contact-forms__back--visible');
  }

  showOptions() {
    this.options.classList.remove('contact-forms__options--hidden');
    this.formContainer.classList.remove('contact-forms__form--visible');
    this.backContainer.classList.remove('contact-forms__back--visible');

    if (this.currentForm) {
      this.currentForm.destroy();
      this.currentForm = null;
    }
  }

  showForm() {
    this.options.classList.add('contact-forms__options--hidden');
    this.formContainer.classList.add('contact-forms__form--visible');
  }

  get open() {
    return this.container.classList.contains('contact-forms--open');
  }

  set open(value) {
    if (value) {
      if (window.innerWidth < 1024) {
        document.body.classList.add('no-scroll');
      }

      this.container.classList.add('contact-forms--open');
    } else {
      document.body.classList.remove('no-scroll');
      this.container.classList.remove('contact-forms--open');
      this.showOptions();
    }
  }

  show(formShortCode) {
    if (this.numOptions === 1) {
      formShortCode = this.options.querySelector('[data-contact-form]').dataset.contactForm;
    }

    if (formShortCode !== "all") {
      this.options.classList.add('contact-forms--noanimation')
      this.showContactForm(formShortCode);
      window.requestAnimationFrame(() => {
        this.options.classList.remove('contact-forms--noanimation')
      });
    }

    this.open = true;
  }

  hide() {
    this.open = false;
  }
}

const contactForms = new ContactForms();

export const initializeContact = () => {
  const buttons = document.querySelectorAll('[data-contact]');

  buttons.forEach(button => {
    button.addEventListener('click', e => {
      contactForms.show(button.dataset.contact);
      e.preventDefault();
      e.stopPropagation();
    });
  });

  const params = new URLSearchParams(window.location.search);

  let showForm = params.get('chat') === 'sales' ? 'all' : params.get('chat');

  if (params.get('contact')) {
    showForm = params.get('contact');
  }

  if (showForm) {
    contactForms.show(showForm);
  }
};
