const ProgressBar = (element, opts = {}) => {
  const barValue = element.children[0];
  const options = {
    vertical: false,
    playing: true,
    duration: 0,
    ...opts,
  };

  let playing = options.playing;
  let vertical = options.vertical;

  const setDuration = (duration) => {
    barValue.style.animationDuration = `${duration}s`;
  };

  const play = () => {
    playing = true;
    barValue.style.animationPlayState = "running";
  };

  const stop = () => {
    playing = false;
    barValue.style.animationPlayState = "paused";
  };

  const updateOrientation = () => {
    if (vertical) {
      element.classList.add("progress-bar__vertical");
    } else {
      element.classList.remove("progress-bar__vertical");
    }
  };

  const setVertical = (value) => {
    vertical = value;
  };

  const progress = (value) => {
    if (opts.vertical) {
      barValue.style.transform = `translate3d(0, -${100 - (value * 100)}%, 0)`;
    } else {
      barValue.style.transform = `translate3d(-${100 - (value * 100)}%, 0, 0)`;
    }
  };

  const init = () => {
    barValue.style.animationPlayState = "paused";
    setDuration(2);
    updateOrientation();
  };

  const destroy = () => {

  };

  init();

  return {
    play,
    stop,
    setVertical,
    setDuration,
    destroy,
    progress
  };
};

export default ProgressBar;
