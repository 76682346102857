import { PopupBase } from './popupBase';
import { initializeComponents } from '../../../_scripts/loadComponents';

export class PopupWithModules extends PopupBase {
  constructor(id, options, element) {
    super(id, options, element);
  }

  async loadPopupAndAddToDOM() {
    if (this.popupFullyLoaded) return super.loadPopupAndAddToDOM();

    const checkLoadingResource = (resolve, reject) => {
      if (this.loadingResources) {
        setTimeout(() => {
          checkLoadingResource(resolve, reject);
        }, 200);
      } else {
        resolve();
      }
    };

    return super.loadPopupAndAddToDOM().then(() =>
      new Promise((resolve, reject) => checkLoadingResource(resolve, reject)).then(() => {
        initializeComponents(this.popup, true);
        this.popupFullyLoaded = true;
      }),
    );
  }
}