import { SimplifiedEventListener } from '../../../../../../_scripts/utils/simplifiedEventListener';

export class NavigationItem extends SimplifiedEventListener {
  constructor(element, callbacks) {
    super(element);

    this.container = element;
    this.callbacks = callbacks;
    this.key = this.container.dataset.navButton;
    this.visible = false;
    this.active = false;
    this.mouseMoved = false; // added this line
    this.mouseTimeout = null;

    this.init();
  }

  init() {
    this.on('mouseover', () => {
      this.mouseTimeout = setTimeout(() => {
        this.mouseMoved = true;

        setTimeout(() => {
          this.mouseMoved = false;
        }, 100);

        this.show();
      }, 150);
    });

    this.on('mouseout', () => {
      if (this.mouseTimeout) {
        clearTimeout(this.mouseTimeout);
        this.mouseTimeout = null;
      }
      if (this.active) {
        this.hide();
      }
    });

    this.on('click', event => {
      if (this.mouseMoved && this.active) {
        event.preventDefault();
        this.mouseMoved = false;
        return;
      }
      this.active ? this.hide() : this.show();
    });
  }

  show() {
    if (this.callbacks.show) {
      this.callbacks.show(this.key);
    }
  }

  hide() {
    if (this.callbacks.hide) {
      this.callbacks.hide(this.key);
    }
  }

  set active(value) {
    if (value === this._active) {
      return;
    }

    if (value) {
      this.container.classList.add('nav__button--selected');
      this.visible = true;
    } else {
      this.container.classList.remove('nav__button--selected');
      this.visible = false;
    }
    this._active = value;
  }

  get active() {
    return this._active;
  }
}
