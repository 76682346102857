export class NavAnalytics {
  constructor(container, category, selector = "a") {
    this.category = category;
    this.container = container;
    this.selector = selector;
    this.init();
  }

  init() {
    this.trackNavClicks();
  }

  trackClick(e) {
    const item = e.target;
    const eventData = {
      event: "navItemClick",
      category: this.category,
      label: item.innerText,
      url: item.href,
    };

    if (
      (!eventData.label &&
      !eventData.url) ||
      (eventData.label.length === 0 &&
      eventData.url.length === 0)
    ) {
      return;
    }
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(eventData);
  }

  trackNavClicks() {
    const navItems = this.container.querySelectorAll(this.selector);

    navItems.forEach((item) => {
      item.addEventListener("click", (e) => this.trackClick(e));
    });
  }
}
