const { debounce } = require('../../../_utils/events');

class TextHoverWallItem {
  constructor(element, container) {
    this.container = container;
    this.element = element;
    this.image = this.element.querySelector('.skydio-image-wrapper');

    if (this.image) {
      this.checkImagePosition();
      // on browser resize, recalculate image position
      window.addEventListener('resize', debounce(this.checkImagePosition.bind(this), 500));
    }
  }

  checkImagePosition() {
    // If we've already resized, reset to calculate again
    if (this.image.style.right) {
      this.image.style.right = '0';
    }

    const containerPosition = this.container.getBoundingClientRect();
    const edgePadding = 8;
    const imagePosition = this.image.getBoundingClientRect();

    if (imagePosition.right > containerPosition.right - edgePadding) {
      this.image.style.right = imagePosition.right - containerPosition.right + edgePadding + 'px';
    }
  }
}

class TextHoverWall {
  constructor(element) {
    this.container = element;
    this.items = Array.from(this.container.querySelectorAll('.text-hover-wall__item')).map(
      el => new TextHoverWallItem(el, this.container),
    );

    // After TextHoverWallItem is initialized, remove the class that hides the content overflow
    this.container.classList.remove('text-hover-wall--initial');
  }
}

const TextHoverWallModule = element => {
  return {
    textHoverWall: new TextHoverWall(element),
  };
};

export default TextHoverWallModule;
