import { htmlToElement as el } from "../../../_scripts/utils/dom";
import Modal from "../../modules/modal/script";
import VideoPlayer from "../video/script";

const VideoThumbAndModal = (element) => {
  const button = element.querySelector("[data-video-modal-button]");
  const modalContentToCopy = element.querySelector("[data-video-modal-content]");

  let modal = null;
  let video = null;
  let modalContent = null;

  const closeModal = () => {
    video.destroy();
    video = null;
    modal.destroy();
  };

  const onOpenModal = () => {
    modalContent = el(modalContentToCopy.innerHTML.toString());
    modal = Modal(modalContent, "video", { show: true, onClose: () => closeModal() });
    video = VideoPlayer(modal.container.querySelector(".sanity-video"), { autoplay: true });
  };

  const bindEvents = () => {
    button.addEventListener("click", () => onOpenModal());
  };

  const init = () => {
    bindEvents();
  };

  const destroy = () => {
    video.destroy();
  };

  init();

  return {
    destroy,
  };
};

export default VideoThumbAndModal;
