// Class that takes a container element and adds the 'ascend-sticky-reg--fixed' class once
// the element is in full view or below the elements parent element. If the user scrolls
// back up past the parent, the class is removed.
class AscendStickyReg {
  constructor(element) {
    this.container = element;
    this.footer = document.querySelector('footer.site-footer');
    this.hideAtModuleId = this.container.dataset.ascendStickyRegHideAt;
    this.showAt = this.container.dataset.ascendStickyRegShowAt;
    this.closeButton = this.container.querySelector('[data-ascend-sticky-reg-close]');
    this.addListeners();
    this.checkScroll();
  }

  // Adds event listeners to the window.
  addListeners() {
    window.addEventListener('scroll', this.checkScroll.bind(this));
    window.addEventListener('resize', this.checkScroll.bind(this));

    if (this.closeButton) {
      this.checkPreviouslyClosed();
      this.closeButton.addEventListener('click', this.close.bind(this));
    }
  }

  // Checks if the sticky reg has been closed previously and if so, closes it.
  checkPreviouslyClosed() {
    if (localStorage.getItem('ascend-sticky-reg-closed') === "true") {
      this.close();
    }
  }

  // Closes the sticky reg.
  close() {
    this.container.classList.add('ascend-sticky-reg--closed');
    localStorage.setItem('ascend-sticky-reg-closed', "true");
  }

  set sticky(value) {
    if (this._sticky === value) {
      return;
    }

    if (value) {
      this.container.classList.add('ascend-sticky-reg--fixed');
    } else {
      this.container.classList.remove('ascend-sticky-reg--fixed');
    }

    this._sticky = value;
  }

  get sticky() {
    return this._sticky;
  }

  checkVisibleByScrollPosition() {
    const scrollPosition = window.scrollY || window.pageYOffset;
    const showAtPercentage = this.showAt ? parseInt(this.showAt) / 100 : 0;
    const showAtScrollPosition =
      (document.body.scrollHeight - window.innerHeight) * showAtPercentage;

    return scrollPosition >= showAtScrollPosition;
  }

  checkVisibleByElementPosition() {
    const rect = this.container.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    let vertInView = rect.top + rect.height <= windowHeight;

    if (this.sticky) {
      const rectParent = this.container.parentElement.getBoundingClientRect();
      vertInView = rectParent.top + rect.height <= windowHeight; //&& rect.top + rect.height >= 0;
    }

    return vertInView;
  }

  checkScroll() {
    this.sticky = this.showAt
      ? this.checkVisibleByScrollPosition()
      : this.checkVisibleByElementPosition();

    this.checkHiddenAndHide();
  }

  checkHiddenAndHide() {
    const stickyContainerRect = this.container.getBoundingClientRect();
    const elementsToCheck = [this.footer];

    if (this.hideAtModuleId) {
      elementsToCheck.push(document.querySelector(`[data-module-id="${this.hideAtModuleId}"]`));
    }

    let hide = false;

    elementsToCheck.forEach(element => {
      if (element) {
        const elRect = element.getBoundingClientRect();
        const vertInView = stickyContainerRect.top + stickyContainerRect.height > elRect.top;

        if (vertInView) {
          hide = true;
        }
      }
    });

    if (hide || !this.sticky && this.showAt) {
      this.container.classList.add('ascend-sticky-reg--hidden');
    } else {
      this.container.classList.remove('ascend-sticky-reg--hidden');
    }
  }
}

const ascendStickyReg = element => new AscendStickyReg(element);

export default ascendStickyReg;
