import Swiper, { A11y, Autoplay, Keyboard, Navigation, Pagination } from 'swiper';

class VariableContentCarouselModule {
  constructor(element) {
    this.container = element;
    this.options = JSON.parse(this.container.dataset.variableContentCarousel);
    this.init();
  }

  init() {
    const options = {
      ...this.options,
      pagination: {
        clickable: true,
        el: this.container.querySelector('.swiper-pagination'),
      },
      navigation: {
        nextEl: this.container.querySelector('.variable-content-carousel__next'),
        prevEl: this.container.querySelector('.variable-content-carousel__prev'),
      },
      watchOverflow: true,
      effect: 'slide',
      speed: 1000,
      modules: [A11y, Navigation, Autoplay, Pagination, Keyboard],
    };
    this.slider = new Swiper(this.container.querySelector('.swiper'), options);
  }
}

export default element => new VariableContentCarouselModule(element);
