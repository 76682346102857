const GoogleAnalyticsEvent = (element) => {
  const category = element.dataset.gaCategory;
  const label = element.dataset.gaLabel;

  const onClick = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "buttonClick",
      label: label,
      category: category
    })
  }

  const init = () => {
    element.addEventListener("click", () => onClick());
  };

  init();
};

const GoogleAnalytics = () => {
  const setUpButtonEvents = () => {
    const buttons = document.querySelectorAll("[data-ga-category]");
    buttons.forEach((b) => GoogleAnalyticsEvent(b));
  };

  const init = () => {
    setUpButtonEvents();
  };

  init();
};

const Analytics = () => {
  GoogleAnalytics();
};

export default Analytics;
