export class LanguageSwitcherBase {
  constructor(element) {
    this.container = element;
    this.initEventListeners();
  }

  setLocaleCookie(value) {
    const date = new Date();
    date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000)); // set cookie to expire in 30 days
    const expires = "expires=" + date.toUTCString();
    document.cookie = "locale=" + value + ";" + expires + ";path=/";
  }

  initEventListeners() {
    this.container?.querySelectorAll("a").forEach((el) => {
      el.addEventListener("click", () => {
        this.setLocaleCookie(el.dataset.lsLang);
        window.location.reload();
        return false;
      });
    });
  }
}
