export class SimplifiedEventListener {
  constructor(element) {
    if (!element) {
      return;
    }

    this.container = element;
    this.events = [];
    this.mouseOver = false;
    this._addEventListeners();
  }

  _createEventListener(event, eventKey) {
    this.events[eventKey ?? event] = [];
    this.container.addEventListener(event, (e) => this.callEvents(eventKey ?? event, e));
  }

  _addEventListeners() {
    this._createEventListener("mouseover");
    this._createEventListener("mouseout");
    this._createEventListener("keydown", "itemselected");
  }

  fireAllEvents(eventKey) {
    this.events[eventKey].forEach((func) => func(this));
  }

  callEvents(eventKey, event) {
    switch (eventKey) {
      case "mouseover":
        this.mouseOver = true;
        break;
      case "mouseout":
        this.mouseOver = false;
        break;
      case "itemselected":
        this.mouseOver = event.keyCode === 32 || event.keyCode === 13;
        break;
      default:
        break;
    }

    this.fireAllEvents(eventKey);
  }

  on(event, func) {
    if (!this.events[event]) {
      this._createEventListener(event);
    }

    this.events[event].push(func);
  }
}
