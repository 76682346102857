import { htmlToElement as el } from '../../../_scripts/utils/dom';
import VideoPlayer from "../video/script";
import Modal from "../../modules/modal/script";

export class ButtonVideoPopup {
  constructor(button) {
    this.button = button;
    this.videoContainer = this.button.querySelector("[data-button-video-full]"); // this.button.previousElementSibling;
    this.videoElement = null;
    this.videoClass = null;
    this.video = null;
    this.modal = null;
    this.init();
  }

  closeModal() {
    this.video.pause();
    this.video.currentTime(0);
  }

  playVideo() {
    this.initializeVideo();

    if (this.modal) {
      this.modal.show();
      this.video.play();
    } else {
      this.modal = Modal(this.videoElement, "video", {
        show: true,
        onClose: () => this.closeModal(),
      });
    }
  }

  initializeVideo() {
    if (!this.videoClass) {
      this.videoClass = VideoPlayer(
        this.videoElement,
        {
          autoplay: true,
        },
        {
          onActivate: (v) => {
            this.video = v;
          },
        }
      );
    }
  }

  addEventListeners() {
    this.button.addEventListener("click", () => this.playVideo());
    this.button.addEventListener("keydown", (e) => {
      if (e.keyCode === 13) {
        this.playVideo();
      }
    });
  }

  init() {
    if (!this.videoContainer && this.button.dataset.buttonVideoPid) {
      const newVideoContainer = el(`<div class="hidden" data-button-video-full>
          <div class="sanity-video" data-video="{&quot;controls&quot;:true}" data-video-overrides="{}">
            <div class="sanity-video__play-button-wrapper sanity-video__play-button--hidden">
              <div class="sanity-video__play-button">
              </div>
            </div>
            <div class="sanity-video__poster-image"></div>
            <video id="${this.button.dataset.buttonVideoPid}" class="video-js vjs-16-9" controls preload="auto">
              <source src="${this.button.dataset.buttonVideoPid}" type="video/mux">
            </video>
          </div>
        </div>`)
      this.button.append(newVideoContainer);
      this.videoContainer = newVideoContainer;
    }

    this.videoElement = this.videoContainer.firstElementChild
    this.videoContainer.removeChild(this.videoElement);
    this.addEventListeners();
  }
}

export default ({ parent }) => {
  const buttons = parent.querySelectorAll("[data-button-video]");
  Array.from(buttons).forEach((button) => {
    new ButtonVideoPopup(button);
  });
};
