class ButtonDropContent {
  constructor(element) {
    this.container = element;
    const settings = JSON.parse(element.dataset.buttonDropContent);
    this.moduleId = settings.moduleId;
    this.containerModuleId = settings.containerModuleId;
    this.addEventListeners();
  }

  addEventListeners() {
    this.container.addEventListener('click', this.handleClick.bind(this));
    window.addEventListener("dropInContentOpen", (e) => {
      if (e.detail.moduleId === this.moduleId) {
        this.open = true;
      }
    });
    window.addEventListener("dropInContentClose", (e) => {
      if (e.detail.moduleId === this.moduleId) {
        this.open = false;
      }
    });

  }

  get open() {
    return this.container.classList.contains('is-active');
  }

  set open(value) {
    if (value) {
      this.container.classList.add('is-active');
    } else {
      this.container.classList.remove('is-active');
    }
  }

  handleClick() {
    window.dispatchEvent(new CustomEvent("dropInContentToggle", { detail: { moduleId: this.moduleId, containerModuleId: this.containerModuleId } }));
  }
}

export default (element) => new ButtonDropContent(element);
