import Swiper, {A11y, Autoplay, EffectFade, Keyboard, Navigation, Pagination} from "swiper";

const SimpleImageCarousel = (element) => {
  const swiperContainer = element.querySelector(".swiper");
  const sliderOptions = {
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 12,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    loop: true,
    pagination: {
      clickable: true,
      el: ".swiper-pagination",
    },
    effect: "fade",
    fadeEffect: { crossFade: true },
    speed: 2000,
  };
  let slider = null;

  const destroy = () => {
    if (slider) {
      slider.destroy();
      slider = null;
    }
  };

  const init = () => {
    slider = new Swiper(swiperContainer, {
      ...sliderOptions,
      modules: [A11y, EffectFade, Navigation, Autoplay, Pagination, Keyboard],
    });
  };

  init();

  return {
    destroy,
  };
};

export default SimpleImageCarousel;
