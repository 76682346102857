import Cookie from "js-cookie";

export default function SetUtmCookie() {
  const cookieName = "utmcookie";
  const utmParams = ["utm_campaign", "utm_source", "utm_medium", "utm_content"];
  const cookieValues = [];
  const currentCookie = Cookie.get(cookieName);

  utmParams.forEach((key) => {
    const currentParam = getParameterByName(key.toLowerCase());
    if (currentParam) {
      cookieValues.push({
        key: key,
        value: currentParam,
      });
    }
  });
  if (currentCookie && cookieValues.length > 0) {
    Cookie.set(
      cookieName,
      JSON.stringify(Object.assign({}, JSON.parse(currentCookie), cookieValues)),
      {
        domain:
          window.location.hostname.indexOf("skydio.com") > -1
            ? "skydio.com"
            : window.location.hostname,
      }
    );
  } else {
    Cookie.set(cookieName, JSON.stringify(cookieValues), {
      domain:
        window.location.hostname.indexOf("skydio.com") > -1
          ? "skydio.com"
          : window.location.hostname,
    });
  }
}
function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
