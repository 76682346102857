window.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("[data-ls-lang]").forEach((el) => {
    el.addEventListener("click", () => {
      window.localStorage.setItem("lang", el.dataset.lsLang);
    });
  });

  const currentLang = document.querySelector("[data-ls-current]")?.dataset?.lsCurrent || "en-us";
  const selectedLang = window.localStorage.getItem("lang") ?? "en-us";

  if (currentLang !== selectedLang) {
    const altUrl = document.querySelector(`[data-ls-lang="${selectedLang}"]`);
    if (!altUrl) return;
    const alternate = new URL(altUrl);
    const currentUrl = new URL(window.location.href);
    if (
      (currentLang === "en-us" &&
        alternate &&
        (alternate.pathname !== `/${selectedLang}` || currentUrl.pathname === "/")) ||
      (currentLang !== "en-us" && alternate)
    ) {
      // window.location.href = alternate.toString();
    }
  }
});
