import { PopupWithModules } from './popupWithModules';
import { getScrollPositionAsPercentage } from '../../../_scripts/utils/scroll';

export class PopupWithScrollTrigger extends PopupWithModules {
  constructor(id, options, element) {
    super(id, options, element);
    this.options.hookPercentage = options.hookPercentage;
    this.previousScrollPosition = -1;
  }

  onScroll() {
    if (this.previousScrollPosition >= this.options.hookPercentage) {
      return;
    }

    this.previousScrollPosition = getScrollPositionAsPercentage();

    if (this.previousScrollPosition >= this.options.hookPercentage) {
      this.show();
    }
  }

  addListeners() {
    window.addEventListener('scroll', this.onScroll.bind(this));
    super.addListeners();
  }
}