import VideoPlayer from '../../macros/video/script';

class X10Hero {
  constructor(element) {
    this.container = element;
    this.video = null;
    this.played = false;
    this.desktopVideo = element.querySelector('.bg-media__media--desktop [data-video]');
    this.mobileVideo = element.querySelector('.bg-media__media--mobile [data-video]');
    this.init();
  }

  get video() {
    return this._video;
  }

  set video(value) {
    if (value !== this._video?.element) {
      if (this._video) {
        this.video.destroy();
      }

      this._video = VideoPlayer(
        value,
        { muted: true, loop: true },
        { onActivate: this.onVideoActivate.bind(this) },
      );
    }
  }

  onVideoActivate(video) {
    video.on('timeupdate', this.onVideoTimeUpdate.bind(this));
    if (video.readyState >= 4) {
      video.play();
    } else {
      video.on('canplaythrough', () => {
        video.play();
      });
    }
  }

  onVideoTimeUpdate() {
    const currentTime = this.video.video.currentTime();
    if (currentTime >= 1.3 && !this.played) {
      this.played = true;
      this.container.classList.add('x10-hero__outer--end');
    } else if (currentTime < 0.5 && this.container.classList.contains('x10-hero__outer--end')) {
      this.container.classList.remove('x10-hero__outer--end');
      this.played = false;
    }
  }

  setupVideo() {
    if (window.innerWidth < 768 && this.mobileVideo) {
      this.video = this.mobileVideo;
    } else {
      this.video = this.desktopVideo;
    }
  }

  init() {
    this.setupVideo();
  }
}

export default element => new X10Hero(element);
